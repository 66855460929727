import axios from 'axios'
import { get } from 'dot-prop'
import isDateExpired from '../common/isDateExpired'
import isDateNearlyExpired from '../common/isDateNearlyExpired'
import dialogsession from '../common/dialogSession'
import {
  API_OAUTH,
  API_LOGIN,
  API_REGISTER,
  API_FORGOT_PASSWORD,
  API_TOKEN_FORGOT_PASSWORD,
  API_REFRESH_TOKEN,
  API_UPLOAD,
  API_VALID_RECOMMENDATION,
  API_SUBMIT_RECOMMENDATION
} from '@/constants/apis'
import Hashids from 'hashids'
const hashids = new Hashids(process.env.VUE_APP_SALT, 20)

const TIMEOUT = 10000

const getBaseUrl = () => {
  let APIBaseUrl = 'https://admisi.sttaa.ac.id'
  // let APIBaseUrl = 'http://143.198.87.244:4000'
  return APIBaseUrl
}

const promiseTimeout = (options = {}) => {
  return new Promise((resolve) => {
    const customTimeout = Object.keys(options).indexOf('timeout') >= 0
      ? options.timeout
      : TIMEOUT
    setTimeout(() => {
      resolve({
        success: false,
        resultInfo: {
          resultMsg: 'Network Error',
          resultCode: 'ERROR_NETWORK'
        },
      })
    }, customTimeout)
  })
}

const promiseFetch = (tAxios, apiUrl, param, options) => {
  return new Promise((resolve, reject) => {
    if (options.methods === "GET") {
      tAxios
        .get(apiUrl)
        .then(res => {
          let success = get(res, 'data.success', false)
          let body = get(res, 'data.data', {})
          if (!success) {
            const errorCode = get(res, 'data.error_code', '')
            if (errorCode === 1 || errorCode === 401) {
              localStorage.removeItem('feConfig')
              dialogsession.show(true)
            }
            body = {
              resultInfo: {
                resultMsg: get(res, 'data.message', ''),
                resultCode: errorCode
              }
            }
          }
          const data = {...body, success}
          resolve(data)
        }).catch(thrown => {
          if (axios.isCancel(thrown)) {
            console.log('Request canceled')
          } else {
            resolve({
              success: false,
              resultInfo: {
                resultMsg: 'System Error',
                resultCode: 'ERROR_SYSTEM'
              },
            })
          }
        })
    } else {
      tAxios
        .post(apiUrl, param, {})
        .then(res => {
          let success = get(res, 'data.success', false)
          let body = get(res, 'data.data', {})
          if (!success) {
            const errorCode = get(res, 'data.error_code', '')
            if (errorCode === 1 || errorCode === 401) {
              localStorage.removeItem('feConfig')
              dialogsession.show(true)
            }
            body = {
              resultInfo: {
                resultMsg: get(res, 'data.message', 'Sistem sibuk, silakan coba beberapa saat lagi.'),
                resultCode: errorCode
              }
            }
          }
          const data = {...body, success}
          resolve(data)
        })
        .catch(thrown => {
          if (axios.isCancel(thrown)) {
            console.log('Request canceled')
          } else {
            resolve({
              success: false,
              resultInfo: {
                resultMsg: 'System Error',
                resultCode: 'ERROR_SYSTEM'
              },
            })
          }
        })
    }
  })
}

export const getConfig = () => {
  let config = false
  try {
    config = JSON.parse(localStorage.getItem('feConfig'))
  } catch (e) {
    console.warn('invalid config')
  }
  return config
}
export const fetchOauth = () => {
  const apiUrl = `${getBaseUrl()}/api/oauth`
  const tAxios = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
  })
  
  const pass = parseInt(process.env.VUE_APP_PASSWORD)
  const param = {
    username: process.env.VUE_APP_USERNAME || '',
    password: hashids.encode(pass) || '',
    grant_type: process.env.VUE_APP_GRANT_TYPE || ''
  }
  return promiseFetch(tAxios, apiUrl, param, {})
}
export const isTokenValid = () => {
  const config = getConfig()
  const token = get(config, 'token', '')
  const expiredAt = get(config, 'expiredAt', '')
  return token && !isDateExpired(expiredAt)
}

const isRefreshToken = async (url) => {
  const apisNoNeedRefreshToken = [
    API_OAUTH,
    API_LOGIN,
    API_REGISTER,
    API_FORGOT_PASSWORD,
    API_TOKEN_FORGOT_PASSWORD,
    API_UPLOAD,
    API_VALID_RECOMMENDATION,
    API_SUBMIT_RECOMMENDATION,
  ]
  const config = getConfig()
  if (apisNoNeedRefreshToken.indexOf(url) === -1 && isDateNearlyExpired(get(config, 'expiredAt', ''))) {
    await refreshToken(config)
  } else if (apisNoNeedRefreshToken.indexOf(url) !== -1 && !isTokenValid()) {
    await setOauth()
  }
}

const setOauth = async () => {
  const res = await fetchOauth()
  if (res.success) {
    const feConfig = {
      token: res.access_token || '',
      expiredAt: res.expires_in || ''
    }
    await localStorage.setItem('feConfig', JSON.stringify(feConfig))
  }
}

const refreshToken = async (config) => {
  const tAxios = axios.create({
    headers: { 
      'Authorization': `Bearer ${config.token}`,
      'Content-Type': 'application/json',
    },
  })
  const apiUrl = `${getBaseUrl()}/api/${API_REFRESH_TOKEN}`
  const res = await promiseFetch(tAxios, apiUrl,{}, {
    methods: 'GET'
  })
  const success = get(res, 'success', false)
  if (success) {
    const feConfig = {
      token: get(res, 'access_token', ''),
      expiredAt: get(res, 'expires_in', ''),
      isLogin: true,
      role: get(res, 'role', '')
    }
    await localStorage.setItem('feConfig', JSON.stringify(feConfig))
  }
}

const request = async (url, param = {}, options = {}) => {
  await isRefreshToken(url)
  const config = getConfig()
  const token = config && config.token || ''
  const APIBaseUrl = getBaseUrl()
  const apiUrl = `${APIBaseUrl}/api/${url}`
  let contentType = 'application/json'
  if (options.content) {
    contentType = options.content
  }
  const tAxios = axios.create({
    headers: { 
      'Authorization': `Bearer ${token}`,
      'Content-Type': contentType,
      'Cache-Control': 'no-cache'
    },
  })

  const pTimeout = promiseTimeout(param)
  const pFetch = promiseFetch(tAxios, apiUrl, param, options)

  return Promise.race([pFetch, pTimeout])
}

export default request