import request from '@/utils/request'
import { get } from 'dot-prop'
import {
  SET_FORM,
  INIT_FORM,
  ADD_FORM,
  REMOVE_FORM,
  INIT_FORM_COUPLE_AGREEMENT,
  INIT_FORM_HEALTH,
  INIT_FORM_FIN,
  ADD_FORM_HEALTH,
  REMOVE_FORM_HEALTH,
  INIT_FORM_RECOMMENDATION,
  INIT_FORM_FILE_COMPLETION,
  SET_SELECTED_ACADEMIC_YEAR,
  SET_ACADEMIC_YEAR,
  SET_COMPLETION_FORM,
  SET_STUDENT_DETAIL,
  ADD_FORM_COMP_EDU,
  REMOVE_FORM_COMP_EDU
} from './mutation-types'
import {
  personal_data,
  family_data,
  marriage_data,
  health_history_data,
  skill_data,
  education_history_data,
  job_history_data,
  service_history_data,
  church_member_data,
  financial_data,
  personal_spiritual_data,
  personal_struggle_data,
  other_data,
  agreement_data,
  couple_agreement_form,
  health_related_file_url,
  health_form_url,
  financial_support_url,
  recommendationData,
  fileCompletionData,
  university_history_data,
  full_education_history_data,
  transcript_edu
} from '@/constants/formContent'

import {
  API_GET_ACADEMIC_YEAR,
  API_FORM_REGISTRATION_ENTRY,
  API_DASH_FORM_DETAIL
} from '@/constants/apis'
import { STH } from '../constants/programStudy'

export default {
  setForm ({ commit }, data) {
    commit(SET_FORM, data) 
  },
  addForm({ commit }, data) {
    commit(ADD_FORM, data) 
  },
  removeForm({ commit }, data) {
    commit(REMOVE_FORM, data) 
  },
  getFormSth ({commit}) {
    const forms = {
      concentration:'Penggembalaan',
      personal_data: JSON.parse(JSON.stringify(personal_data)),
      family_data: JSON.parse(JSON.stringify(family_data)),
      marriage_data: JSON.parse(JSON.stringify(marriage_data)),
      health_history_data: JSON.parse(JSON.stringify(health_history_data)),
      skill_data: JSON.parse(JSON.stringify(skill_data)),
      education_history_data: JSON.parse(JSON.stringify(education_history_data)),
      job_history_data: JSON.parse(JSON.stringify(job_history_data)),
      service_history_data: JSON.parse(JSON.stringify(service_history_data)),
      church_member_data: JSON.parse(JSON.stringify(church_member_data)),
      financial_data: JSON.parse(JSON.stringify(financial_data)),
      personal_spiritual_data: JSON.parse(JSON.stringify(personal_spiritual_data)),
      personal_struggle_data: JSON.parse(JSON.stringify(personal_struggle_data)),
      other_data: JSON.parse(JSON.stringify(other_data)),
      agreement_data: JSON.parse(JSON.stringify(agreement_data)),
    }
    commit(INIT_FORM, {
      forms: {...forms},
      prodi: 'sth'
    })
  },
  getFormMthu ({commit}) {
    const forms = {
      concentration:'Studi Biblika',
      personal_data: JSON.parse(JSON.stringify(personal_data)),
      family_data: JSON.parse(JSON.stringify(family_data)),
      marriage_data: JSON.parse(JSON.stringify(marriage_data)),
      health_history_data: JSON.parse(JSON.stringify(health_history_data)),
      skill_data: JSON.parse(JSON.stringify(skill_data)),
      education_history_data: JSON.parse(JSON.stringify(education_history_data)),
      job_history_data: JSON.parse(JSON.stringify(job_history_data)),
      service_history_data: JSON.parse(JSON.stringify(service_history_data)),
      church_member_data: JSON.parse(JSON.stringify(church_member_data)),
      financial_data: JSON.parse(JSON.stringify(financial_data)),
      personal_spiritual_data: JSON.parse(JSON.stringify(personal_spiritual_data)),
      personal_struggle_data: JSON.parse(JSON.stringify(personal_struggle_data)),
      other_data: JSON.parse(JSON.stringify(other_data)),
      agreement_data: JSON.parse(JSON.stringify(agreement_data)),
    }
    commit(INIT_FORM, {
      forms: {...forms},
      prodi: 'mthu'
    })
  },
  getFormMth ({commit}) {
    const forms = {
      concentration:'Studi Biblika',
      personal_data: JSON.parse(JSON.stringify(personal_data)),
      family_data: JSON.parse(JSON.stringify(family_data)),
      marriage_data: JSON.parse(JSON.stringify(marriage_data)),
      health_history_data: JSON.parse(JSON.stringify(health_history_data)),
      skill_data: JSON.parse(JSON.stringify(skill_data)),
      education_history_data: JSON.parse(JSON.stringify(university_history_data)),
      job_history_data: JSON.parse(JSON.stringify(job_history_data)),
      church_member_data: JSON.parse(JSON.stringify(church_member_data)),
      financial_data: JSON.parse(JSON.stringify(financial_data)),
      personal_struggle_data: JSON.parse(JSON.stringify(personal_struggle_data)).slice(2,4),
      other_data: JSON.parse(JSON.stringify(other_data)),
      agreement_data: JSON.parse(JSON.stringify(agreement_data)),
    }
    commit(INIT_FORM, {
      forms: {...forms},
      prodi: 'mth'
    })
  },
  getFormMmin ({commit}) {
    const forms = {
      concentration: 'Pelayanan Kristen',
      personal_data: JSON.parse(JSON.stringify(personal_data)),
      family_data: JSON.parse(JSON.stringify(family_data)),
      marriage_data: JSON.parse(JSON.stringify(marriage_data)),
      health_history_data: JSON.parse(JSON.stringify(health_history_data)),
      skill_data: JSON.parse(JSON.stringify(skill_data)),
      education_history_data: JSON.parse(JSON.stringify(university_history_data)),
      job_history_data: JSON.parse(JSON.stringify(job_history_data)),
      church_member_data: JSON.parse(JSON.stringify(church_member_data)),
      financial_data: JSON.parse(JSON.stringify(financial_data)),
      personal_struggle_data: JSON.parse(JSON.stringify(personal_struggle_data)).slice(2,4),
      other_data: JSON.parse(JSON.stringify(other_data)),
      agreement_data: JSON.parse(JSON.stringify(agreement_data)),
    }
    commit(INIT_FORM, {
      forms: {...forms},
      prodi: 'mmin'
    })
  },
  getFormMdiv ({commit}) {
    const forms = {
      concentration:'Penggembalaan',
      personal_data: JSON.parse(JSON.stringify(personal_data)),
      family_data: JSON.parse(JSON.stringify(family_data)),
      marriage_data: JSON.parse(JSON.stringify(marriage_data)),
      health_history_data: JSON.parse(JSON.stringify(health_history_data)),
      skill_data: JSON.parse(JSON.stringify(skill_data)),
      education_history_data: JSON.parse(JSON.stringify(university_history_data)),
      job_history_data: JSON.parse(JSON.stringify(job_history_data)),
      service_history_data: JSON.parse(JSON.stringify(service_history_data)),
      church_member_data: JSON.parse(JSON.stringify(church_member_data)),
      financial_data: JSON.parse(JSON.stringify(financial_data)),
      personal_spiritual_data: JSON.parse(JSON.stringify(personal_spiritual_data)),
      personal_struggle_data: JSON.parse(JSON.stringify(personal_struggle_data)).slice(2,4),
      other_data: JSON.parse(JSON.stringify(other_data)),
      agreement_data: JSON.parse(JSON.stringify(agreement_data)),
    }
    commit(INIT_FORM, {
      forms: {...forms},
      prodi: 'mdiv'
    })
  },
  getFormSert ({commit}) {
    const forms = {
      concentration:'Pelayanan Gerejawi',
      personal_data: JSON.parse(JSON.stringify(personal_data)),
      family_data: JSON.parse(JSON.stringify(family_data)),
      marriage_data: JSON.parse(JSON.stringify(marriage_data)),
      education_history_data: JSON.parse(JSON.stringify(full_education_history_data)),
      job_history_data: JSON.parse(JSON.stringify(job_history_data)),
      service_history_data: JSON.parse(JSON.stringify(service_history_data)),
      church_member_data: JSON.parse(JSON.stringify(church_member_data)),
      personal_spiritual_data: JSON.parse(JSON.stringify(personal_spiritual_data)),
      personal_struggle_data: JSON.parse(JSON.stringify(personal_struggle_data)).slice(2,4),
      other_data: JSON.parse(JSON.stringify(other_data)),
      agreement_data: JSON.parse(JSON.stringify(agreement_data)),
    }
    commit(INIT_FORM, {
      forms: {...forms},
      prodi: 'sert'
    })
  },
  getCoupleAgreement ({commit}) {
    const form = {
      couple_agreement_form: JSON.parse(JSON.stringify(couple_agreement_form))
    }
    commit(INIT_FORM_COUPLE_AGREEMENT, form)
  },
  getHealthForm ({commit}) {
    const form = {
      health_related_file_url: JSON.parse(JSON.stringify(health_related_file_url)),
      health_form_url: JSON.parse(JSON.stringify(health_form_url))
    }
    commit(INIT_FORM_HEALTH, form)
  },
  getFinForm ({commit}) {
    const form = {
      financial_support_url: JSON.parse(JSON.stringify(financial_support_url)),
    }
    commit(INIT_FORM_FIN, form)
  },
  addFormHealth({ commit }, data) {
    commit(ADD_FORM_HEALTH, data) 
  },
  removeFormHealth({ commit }, data) {
    commit(REMOVE_FORM_HEALTH, data) 
  },
  getRecommendationData ({commit}, prodi) {
    const recommendData = []
    const recData = JSON.parse(JSON.stringify(recommendationData))
    recData.map(itm => {
      if (itm.category.indexOf(prodi) !== -1) {
        recommendData.push(itm)
      }
    })
    const form = {
      recommendData
    }
    commit(INIT_FORM_RECOMMENDATION, form)
  },
  addEduFile({ commit }, data) {
    commit(ADD_FORM_COMP_EDU, data) 
  },
  removeEduFile({ commit }, data) {
    commit(REMOVE_FORM_COMP_EDU, data) 
  },
  getFileCompletionData ({commit}, prodi) {
    let formCompletion = JSON.parse(JSON.stringify(fileCompletionData))
    let formEduComp = JSON.parse(JSON.stringify(transcript_edu))
    const data = []
    formCompletion.map(itm => {
      if (itm.prodi.indexOf(prodi) !== -1) {
        data.push(itm)
      }
    })
    const dataEdu = []
    formEduComp.map(itm => {
      if (itm.prodi.indexOf(prodi) !== -1) {
        dataEdu.push(itm)
      }
    })
    const form = {
      fileCompletionData: data,
      fileCompletionEdu: dataEdu
    }
    commit(INIT_FORM_FILE_COMPLETION, form)
  },
  setSelectedAcademicYear ({commit}, data) {
    commit(SET_SELECTED_ACADEMIC_YEAR, data)
  },
  getCompletionForm ({commit}, data) {
    return new Promise((_resolve, _reject) => {
      request(API_FORM_REGISTRATION_ENTRY, data).then(res => {
        if (res.success) {
          commit(SET_COMPLETION_FORM, res)
        }
        _resolve(res)
      })
    })
  },
  getDashboardCompletionForm ({commit}, data) {
    return new Promise((_resolve, _reject) => {
      request(API_DASH_FORM_DETAIL, data).then(res => {
        if (res.success) {
          commit(SET_COMPLETION_FORM, res)
          commit(SET_STUDENT_DETAIL, res)
        }
        _resolve(res)
      })
    })
  },
  getAcademicYear ({commit}, data) {
    return new Promise((_resolve, _reject) => {
      request(API_GET_ACADEMIC_YEAR, data).then(res => {
        if (res.success) {
          commit(SET_ACADEMIC_YEAR, res)
        } 
        _resolve(res)
      })
    })
  }
}
