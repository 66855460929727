export const text = {
  // Login Page
  'dash.login.form.title': 'Masuk STT Amanat Agung',
  'dash.login.form.desc': 'Silakan masuk dengan Email dan Password yang sudah didaftarkan.',

  // Reference Page
  'dash.reference.list.title': 'Daftar Mahasiswa',

  // Student Page
  'dash.student.list.title': 'Daftar Form',
  'dash.student.detail.title': 'Detail Mahasiswa',
  'dash.student.detail.name': 'Nama Mahasiswa',
  'dash.student.detail.email': 'E-mail',
  'dash.student.detail.phone': 'Nomor WA',
  'dash.student.detail.status': 'Status',
  'dash.student.detail.prodi': 'Program Studi',
  'dash.student.detail.academic.year': 'Gelombang',
  'dash.student.detail.period': 'Periode',

  // Report Page
  'dash.report.recap.title': 'Laporan Rekapitulasi',
  'dash.report.dikti.title': 'Laporan PD Dikti',
  'dash.report.lable.title': 'Label Surat',
  'dash.report.lable.detail.title': 'Label Detail',
  'dash.report.category.title': 'Kategori Label',

  // Registration Setting
  'dash.regist.setting.title': 'Data Pendaftaran',
  'dash.regist.setting.period.title': 'Data Gelombang Pendaftaran',

  // User Setting
  'dash.user.setting.title': 'Daftar Pengguna',

  // Add User
  'dash.add.user.title': 'Tambah Pengguna',
  'dash.edit.user.title': 'Perbarui Pengguna',
}