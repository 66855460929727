export const text = {
  
  // Home Page
  'home.reference.title': 'Ijinkan kami membantumu.',
  'home.reference.desc': 'Mohon lengkapi data di bawah ini dan kami akan menghubungimu untuk informasi lebih lanjut',
  'home.reference.dialog.title': 'Data Anda berhasil dikirim!',
  'home.reference.dialog.desc': 'Kami akan segera menghubungimu untuk informasi lebih lanjut.',
  'home.reference.dialog.btn': 'MENGERTI',

  // Login Page
  'login.form.title': 'Masuk Pendaftar',
  'login.form.desc': 'Silakan masuk dengan Email dan Password yang sudah didaftarkan.',
  'login.section.right.title': 'Belum punya akun Masuk Pendaftar?',
  'login.section.right.desc': 'Anda perlu melakukan daftar awal untuk mendaftar program studi.',
  'login.section.right.btn': 'Daftar Sekarang',
  'login.dialog.success.title': 'Pemulihan Password Berhasil!',
  'login.dialog.success.desc': 'Silakan lakukan proses Masuk menggunakan password yang kami kirimkan melalui email.',
  'login.dialog.success.btn': 'MENGERTI',
  'login.dialog.fail.title': 'Pemulihan Password Gagal!',
  'login.dialog.fail.desc': 'Silakan kembali ke halaman permulihan password kembali.',
  'login.dialog.fail.btn': 'MENGERTI',

  // Register Page
  'register.form.title': 'Daftar Pendaftar',
  'register.form.desc': 'Silakan daftar dengan mengisi beberapa informasi di bawah ini.',
  'register.section.right.title': 'Sudah punya akun Masuk Pendaftar?',
  'register.section.right.desc': 'Anda perlu melakukan masuk untuk mendaftar program studi.',
  'register.section.right.btn': 'Masuk Sekarang',
  'register.dialog.title': 'Pendaftaran Berhasil!',
  'register.dialog.desc': 'Silakan lakukan proses Masuk menggunakan password yang kami kirimkan melalui email.',
  'register.dialog.btn': 'MASUK SEKARANG',

  // Forgot Password Page
  'forgot.password.form.title': 'Lupa Password',
  'forgot.password.form.desc': 'Silakan masukan Email yang sudah pernah didaftarkan pada proses pendaftaran awal.',
  'forgot.password.dialog.title': 'Pemulihan Password Terkirim!',
  'forgot.password.dialog.desc': 'Silakan periksa email Anda untuk melakukan pemulihan password.',
  'forgot.password.dialog.btn': 'MENGERTI',

  // Profile Page
  'profile.section.left.title': 'Profil Saya',
  'profile.section.left.name': 'Nama',
  'profile.section.left.phone': 'Nomor WA',
  'profile.section.left.email': 'E-mail',
  'profile.section.right.title': 'Ingin ganti password?',
  'profile.section.right.desc': 'Ganti password anda untuk memudahkan anda saat masuk.',
  'profile.section.right.btn': 'Ganti Password',

  // Change Password
  'change.pass.dialog.title': 'Ganti Password Berhasil!',
  'change.pass.dialog.desc': 'Anda sudah bisa masuk dengan password yang baru.',
  'change.pass.dialog.btn': 'MENGERTI',

  // Program Studi Page
  'program.studi.title': 'Program Studi',
  'program.studi.desc': 'Silakan pilih dan lengkapi program studi yang ada di bawah ini.',
  'program.studi.sth.title': 'Program Studi Sarjana Teologi (S.Th.)',
  'program.studi.sth.desc': 'Program studi ini terbuka bagi lulusan SMA dan sederajat.',
  'program.studi.mdiv.title': 'Program Studi Magister Divinitas (M.Div.)',
  'program.studi.mdiv.desc': 'Program studi ini terbuka bagi lulusan SMA dan sederajat.',
  'program.studi.mmin.title': 'Program Studi Magister Ministri (M.Min.)',
  'program.studi.mmin.desc': 'Program studi ini terbuka bagi lulusan SMA dan sederajat.',
  'program.studi.mth.title': 'Program Studi Magister Teologi (M.Th.) S1 Teologi',
  'program.studi.mth.desc': 'Program studi ini terbuka bagi lulusan SMA dan sederajat.',
  'program.studi.mthu.title': 'Program Studi Magister Teologi (M.Th.) S1 Umum',
  'program.studi.mthu.desc': 'Program studi ini terbuka bagi lulusan SMA dan sederajat.',
  'program.studi.sert.title': 'Program Sertifikat Teologi',
  'program.studi.sert.desc': 'Program studi ini terbuka bagi lulusan SMA dan sederajat.',

  // Registration Status
  'registration.status.title': 'Status Pendaftaran',

  // Registration Detail
  'registration.detail.title': 'Detail Pendaftaran',
  'registration.detail.status.program': 'Program Studi',
  'registration.detail.status.periode': 'Periode',

  // Registration Form
  'registration.form.title': 'Formulir Pendaftaran',

  // Payment
  'registration.payment.title': 'Pembayaran',

  // Dialog Info
  'dialog.success': 'Berhasil!',
  'dialog.success.form': 'Data yang Anda kirim berhasil disimpan.',
  'dialog.success.submit.form': 'Data yang Anda kirim berhasil dikirim.',
  'dialog.success.evaluation.form': 'Data anda akan segera diperbarui',
  'dialog.success.generate': 'Data anda sudah terunduh.',
  'dialog.success.remove': 'Data berhasil dihapus.',
  'dialog.recommend.error': 'Terjadi kesalahan!',
  'dialog.recommend.error.desc': 'Pengisian formulir rekomendasi tidak dapat dilakukan lagi.',
  'dialog.success.btn': 'OK',
  'dialog.fail': 'Terjadi kesalahan!',
  'dialog.fail.form': 'Terdapat kesalahan saat mendapatkan data. Silakan coba lagi nanti.',
  'dialog.invalid': 'Data belum sesuai!',
  'dialog.invalid.form': 'Silakan periksa kembali data formulir.',
  'dialog.not.found': 'Halaman tidak ditemukan!',
  'dialog.not.found.form': 'Anda akan diarahkan ke halaman utama',
}