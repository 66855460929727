export const API_LOGIN = 'auth/login'
export const API_REGISTER = 'auth/register'
export const API_REFRESH_TOKEN = 'auth/refresh-token'
export const API_FORGOT_PASSWORD = 'forgot'
export const API_TOKEN_FORGOT_PASSWORD = 'forgot/check'
export const API_OAUTH = 'oauth' 

// PROFILE
export const API_GET_PROFILE = 'profile'
export const CHANGE_PASSWORD = 'auth/change-password'

// REGISTRATION
export const API_FORM_PROGRAM_STUDY = 'form/program-study'
export const API_FORM_REGISTRATION_STATUS = 'form/registration-status'
export const API_FORM_REGISTRATION_ENTRY = 'form/entry-form'
export const API_FORM_REGISTRATION_FORM = 'form/registration-form'
export const API_GET_ACADEMIC_YEAR = 'academic-year'
export const API_GET_REGISTRATION_FEE = 'registration-fee'
export const API_UPLOAD = 'upload'
export const API_FORM_REPENTANCE_STORY = 'form/repentance-story'
export const API_FORM_CALLING_FAITH_STORY = 'form/calling-faith-story'
export const API_FORM_MOTIVATION_STORY = 'form/motivation-story'
export const API_FORM_COUPLE_AGREEMENT = 'form/couple-agreement-form'
export const API_FORM_HEALTH = 'form/health-form'
export const API_FORM_FINANCIAL = 'form/financial-support-form'
export const API_GET_HEALTH = 'health-form'
export const API_GET_FINANCIAL = 'financial-form/program-study'
export const API_FORM_RECOMMENDATION = 'form/recommendation-person'
export const API_FORM_COMPLETENESS = 'form/completeness-data'
export const API_FORM_PAYMENT = 'form/payment-form'
export const API_FORM_SUBMIT = 'form/submit'
export const API_STUDENT_FORM_SUBMIT = 'form/agreement-letter/submit'

// Recommendation
export const API_VALID_RECOMMENDATION = 'form/recommendation/check'
export const API_SUBMIT_RECOMMENDATION = 'form/recommendation/submit'

// Dashboard
export const API_DASH_FORM_LIST = 'dashboard/entry-form/list'
export const API_DASH_FORM_DETAIL = 'dashboard/entry-form/detail'
export const API_DASH_FORM_EVALUATION = 'dashboard/entry-form/resolve'
export const API_DASH_FORM_ACCEPT = 'dashboard/entry-form/accept'
export const API_DASH_FORM_ALUMNI = 'dashboard/entry-form/alumni'
export const API_DASH_FORM_DO = 'dashboard/entry-form/dropout'
export const API_DASH_ACADEMIC_YEAR_LIST = 'dashboard/academic-year/list'
export const API_DASH_ACADEMIC_YEAR_UPDATE = 'dashboard/academic-year/update'
export const API_DASH_ACADEMIC_YEAR_CREATE = 'dashboard/academic-year/create'
export const API_DASH_GET_FINANCIAL = 'dashboard/form/financial-health'
export const API_DASH_FINANCIAL_UPDATE = 'dashboard/form/financial-health/update'
export const API_DASH_GET_STATUS = 'dashboard/form/status'
export const API_DASH_GET_USER_LIST = 'dashboard/user/admin/list'
export const API_DASH_REMOVE_USER = 'dashboard/user/admin/delete'
export const API_DASH_ADD_USER = 'dashboard/user/admin/create'
export const API_DASH_UPDATE_USER = 'dashboard/user/admin/update'
export const API_DASH_GET_STUDENT_LIST = 'dashboard/user/student/list'
export const API_DASH_GET_LECTURER_LIST = 'dashboard/form/lecturer-review/list'
export const API_DASH_LECTURER_SEND = 'dashboard/form/lecturer-review/send'
export const API_DASH_LECTURER_NOTE_SUBMIT = 'dashboard/form/lecturer-review/submit'
export const API_DASH_INTERVIEW_SUBMIT = 'dashboard/entry-form/interview'
export const API_DASH_RECAP = 'dashboard/report/summary'
export const API_DASH_REVERT_STATUS = 'dashboard/entry-form/update-status'

export const API_DASH_REGISTRATION_FORM = 'dashboard/form/registration-form'
export const API_DASH_REPENTANCE_FORM = 'dashboard/form/repentance-story'
export const API_DASH_CALLING_FAITH = 'dashboard/form/calling-faith-story'
export const API_DASH_MOTIVATION_STORY = 'dashboard/form/motivation-story'
export const API_DASH_COUPLE_AGREEMENT = 'dashboard/form/couple-agreement-form'
export const API_DASH_HEALTH_FORM = 'dashboard/form/health-form'
export const API_DASH_RECOMMENDATION_FORM = 'dashboard/form/recommendation-person'
export const API_DASH_FINANCIAL_FORM = 'dashboard/form/financial-support-form'
export const API_DASH_COMPLETENESS_FORM = 'dashboard/form/completeness-data'
export const API_DASH_PAYMENT_FROM = 'dashboard/form/payment-form'
export const API_DASH_FORM_SUBMIT = 'dashboard/form/submit'

export const API_DASH_LABEL_CATEGORY = 'dashboard/report/label-printing/category/list'
export const API_DASH_CREATE_LABEL_CATEGORY = 'dashboard/report/label-printing/category/upsert'
export const API_DASH_REMOVE_LABEL_CATEGORY = 'dashboard/report/label-printing/category/delete'

export const API_DASH_LABEL_LIST = '/dashboard/report/label-printing/list'
export const API_DASH_LABEL_CREATE = 'dashboard/report/label-printing/upsert'
export const API_DASH_LABEL_DELETE = '/dashboard/report/label-printing/delete'
export const API_DASH_LABEL_EXPORT = '/dashboard/report/label-printing/export'
