import Vue from 'vue'
import VueHead from 'vue-head'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import text from '@/utils/common/text'
import { localize } from 'vee-validate'
import id from 'vee-validate/dist/locale/id'
import CKEditor from "@ckeditor/ckeditor5-vue2"


Vue.config.productionTip = false
localize('id', id)

Vue.use(VueHead)
Vue.use(CKEditor)

const filters = { text }

for (const key in filters) {
  Vue.filter(key, filters[key])
}

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
