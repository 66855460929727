<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="common" class="white-overlay">
      <div class="white-overlay__img">
        <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingScreen',
  data() {
    return {
      overlay: false,
      common: false,
    }
  },
  methods: {
    show(isWhite = false) {
      if (isWhite === 'white') {
        this.common = true
      } else {
        this.overlay = true
      }

    },
    hide() {
      this.overlay = false
      this.common = false
    }
  }
}
</script>

<style lang="scss" scoped>
.white-overlay {
  background-color: white;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
