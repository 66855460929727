import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import {
  WEB_HOME,
  PROGRAM_STUDY,
  REGISTRATION_STATUS,
  MY_PROFILE,
  REFERENCE_LIST,
  FORM_LIST,
  REPORT,
  REGISTRATION_SETTING,
  USER_SETTING,
  LABEL
} from '@/constants/pages'

const state = {
  navDefault: [
    {
      name: 'Beranda',
      pathName: WEB_HOME,
      path: '/'
    },
  ],
  navStudent: [
    {
      name: 'Program Studi',
      pathName: PROGRAM_STUDY,
      path: '/program-studi'
    },
    {
      name: 'Status Pendaftaran',
      pathName: REGISTRATION_STATUS,
      path: '/status-pendaftaran'
    },
    {
      name: 'Profil Saya',
      pathName: MY_PROFILE,
      path: '/profil'
    },
  ],
  navAdmin:  [
    {
      name: 'Daftar Mahasiswa',
      pathName: REFERENCE_LIST,
      path: '/dashboard/daftar-mahasiswa'
    },
    {
      name: 'Daftar Form',
      pathName: FORM_LIST,
      path: '/dashboard/daftar-form'
    },
    {
      name: 'Laporan',
      pathName: REPORT,
      path: '/dashboard/laporan'
    },
    {
      name: 'Pendaftaran',
      pathName: REGISTRATION_SETTING,
      path: '/dashboard/pengaturan-pendaftaran'
    },
    {
      name: 'Pengguna',
      pathName: USER_SETTING,
      path: '/dashboard/pengaturan-pengguna'
    }
  ],
  navDosen: [
    {
      name: 'Daftar Form',
      pathName: FORM_LIST,
      path: '/dashboard/daftar-form'
    },
  ],
  navStaff: [
    {
      name: 'Label',
      pathName: LABEL,
      path: '/dashboard/label'
    },
  ],
  formRegist: {},
  formCoupleAgreement: {},
  formHealth: {},
  formFin: {},
  formRecommendation: {},
  formFileCompletion: {},
  selectedAcademicYear: {},
  completionForm: {},
  academicYear: [],
  academic_year_id: '',
  registrationForm: {},
  repentance_story: '',
  calling_faith_story: '',
  motivation_story: '',
  couple_agreement_form: {},
  financial_form: {},
  health_form: {},
  recommendation_form: {},
  completeness_data_form: {},
  payment_form: {},
  status: 'init',
  concentrationList: [
    {
      value: 'Penggembalaan',
      category: ['sth', 'mdiv']
    }, {
      value: 'Pendidikan Kristen',
      category: ['sth', 'mdiv', 'sert']
    }, {
      value: 'Ibadah',
      category: ['sth']
    }, {
      value: 'Pelayanan Pastoral',
      category: ['mth']
    }, {
      value: 'Studi Biblika',
      category: ['mth', 'mthu']
    }, {
      value: 'Teologi Sistematika',
      category: ['mth', 'mthu']
    }, {
      value: 'Pelayanan Kaum Muda',
      category: ['mth']
    }, {
      value: 'Pelayanan Gerejawi',
      category: ['sert']
    }, {
      value: 'Pelayanan Kristen',
      category: ['mmin']
    }, {
      value: 'Pelayanan di Ruang Publik',
      category: ['mmin']
    }, {
      value: 'Sistematika',
      category: ['mthu']
    }
  ],
  studentDetail: {},
  student_agreement_letter: {},
  rejected_reason: '',
  lecturer_notes: [],
  interview: ''
}
Vue.use(Vuex)

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
})
