export const checkHeardFrom = (data) => {
  const res = {}
  data.map(itm => {
    if (itm === 'Alumni STT Amanat Agung.') {
      res.is_alumni = true
    } else if (itm === 'Hamba Tuhan/Rohaniwan.') {
      res.is_servant_of_god = true
    } else if (itm === 'Teman.') {
      res.is_friend = true
    } else if (itm === 'Dosen STT Amanat Agung.') {
      res.is_lecturer = true
    } else if (itm === 'Mahasiswa STT Amanat Agung.') {
      res.is_college_student = true
    } else if (itm === 'Keluarga.') {
      res.is_family = true
    } else if (itm === 'Website resmi STT Amanat Agung.') {
      res.is_website = true
    } else if (itm === 'Media sosial STT Amanat Agung (Instagram, Twitter, Facebook, dll).') {
      res.is_social_media = true
    } else if (itm === 'Media komunikasi (WhatsApp Grup, Line, Telegram, dll).') {
      res.is_communication_media = true
    } else if (itm === 'Poster, Flyer, Warta Gereja.') {
      res.is_poster = true
    } else if (itm === 'Lain-lainnya') {
      res.is_other = true
    }
  })
  return res
}
export const parseHeardFrom = (data) => {
  const res = []
  Object.keys(data).map(itm => {
    if (itm === 'is_alumni' && data.is_alumni) {
      res.push('Alumni STT Amanat Agung.')
    } else if (itm === 'is_servant_of_god' && data.is_servant_of_god) {
      res.push('Hamba Tuhan/Rohaniwan.')
    } else if (itm === 'is_lecturer' && data.is_lecturer) {
      res.push('Dosen STT Amanat Agung.')
    } else if (itm === 'is_friend' && data.is_friend) {
      res.push('Teman.')
    } else if (itm === 'is_college_student' && data.is_college_student) {
      res.push('Mahasiswa STT Amanat Agung.')
    } else if (itm === 'is_family' && data.is_family) {
      res.push('Keluarga.')
    } else if (itm === 'is_website' && data.is_website) {
      res.push('Website resmi STT Amanat Agung.')
    } else if (itm === 'is_social_media' && data.is_social_media ) {
      res.push('Media sosial STT Amanat Agung (Instagram, Twitter, Facebook, dll).')
    } else if (itm === 'is_communication_media' && data.is_communication_media) {
      res.push('Media komunikasi (WhatsApp Grup, Line, Telegram, dll).')
    } else if (itm === 'is_poster' && data.is_poster ) {
      res.push('Poster, Flyer, Warta Gereja.')
    } else if (itm === 'is_other' && data.is_other) {
      res.push('Lain-lainnya')
    }
  })
  return res
}
export const checkAddictionForm = (data) => {
  const res = {}
  data.map(itm => {
    if (itm === 'Narkoba') {
      res.is_drug = true
    } else if (itm === 'Gaming') {
      res.is_gaming = true
    } else if (itm === 'Alkohol') {
      res.is_alcohol = true
    } else if (itm === 'Merokok') {
      res.is_smoking = true
    } else if (itm === 'Pornografi') {
      res.is_porn = true
    } else if (itm === 'Judi') {
      res.is_gambling = true
    } else if (itm === 'Masturbasi') {
      res.is_masturbate = true
    } else if (itm === 'Lain-lainnya') {
      res.is_other = true
    }
  })
  return res
}
export const parseAddictionFrom = (data) => {
  const res = []
  Object.keys(data).map(itm => {
    if (itm === 'is_drug' && data.is_drug) {
      res.push('Narkoba')
    } else if (itm === 'is_gaming' && data.is_gaming) {
      res.push('Gaming')
    } else if (itm === 'is_alcohol' && data.is_alcohol) {
      res.push('Alkohol')
    } else if (itm === 'is_smoking' && data.is_smoking) {
      res.push('Merokok')
    } else if (itm === 'is_porn' && data.is_porn) {
      res.push('Pornografi')
    } else if (itm === 'is_gambling' && data.is_gambling) {
      res.push('Judi')
    } else if (itm === 'is_masturbate' && data.is_masturbate) {
      res.push('Masturbasi')
    } else if (itm === 'is_other' && data.is_other) {
      res.push('Lain-lainnya')
    }
  })
  return res
}
export const checkBCheckbox = (data) => {
  const res = {
    b_checkbox_1: false,
    b_checkbox_2: false,
    b_checkbox_3: false,
    b_checkbox_4: false,
    b_checkbox_5: false,
    b_checkbox_6: false
  }
  data.map(itm => {
    if (itm === 'Tidak sabar, tidak bertoleransi, argumentatif, mendominasi, merengut, sombong, suka mengkritik.') {
      res.b_checkbox_1 = true
    } else if (itm === 'Mudah merasa malu, mudah tersinggung, kecil hati, depresi, mudah kesal.') {
      res.b_checkbox_2 = true
    } else if (itm === 'Sering kuatir, gelisah, grogi atau tegang.') {
      res.b_checkbox_3 = true
    } else if (itm === 'Membeda-bedakan kelompok tertentu, ras atau kebangsaan.') {
      res.b_checkbox_4 = true
    } else if (itm === 'Berteman secara eksklusif.') {
      res.b_checkbox_5 = true
    } else if (itm === 'Kurang rasa humor atau kemampuan untuk menceritakan lelucon.') {
      res.b_checkbox_6 = true
    }
  })
  return res
}