import {
  SET_FORM,
  INIT_FORM,
  ADD_FORM,
  REMOVE_FORM,
  INIT_FORM_COUPLE_AGREEMENT,
  INIT_FORM_HEALTH,
  INIT_FORM_FIN,
  ADD_FORM_HEALTH,
  REMOVE_FORM_HEALTH,
  INIT_FORM_RECOMMENDATION,
  INIT_FORM_FILE_COMPLETION,
  SET_SELECTED_ACADEMIC_YEAR,
  SET_ACADEMIC_YEAR,
  SET_COMPLETION_FORM,
  SET_STUDENT_DETAIL,
  ADD_FORM_COMP_EDU,
  REMOVE_FORM_COMP_EDU
} from './mutation-types'

import {
  INIT,
  ACCEPTED,
  REJECTED,
  ALUMNI,
  DROPOUT
} from '@/constants/status'
import { get } from 'dot-prop'
import moment from 'moment'
import { parseHeardFrom, parseAddictionFrom } from '@/utils/common/checkHeardStta'
import { getConfig } from '@/utils/request'
import { getUrl } from '@/utils/common/getUrl'
import {
  student_agreement_letter
} from '@/constants/formContent'

const disabledFormField = (status, role) => {
  return (status !== INIT && role === 'mahasiswa') || ([INIT, ACCEPTED, REJECTED, ALUMNI, DROPOUT].indexOf(status) !== -1 && role === 'admin')
}
export default {
  [SET_FORM] (_state, data) {
    if (data.parent === 'marriage_data') {
      _state.formRegist.marriage_data.map(itm => {
        if (data.fields === 'is_marriage' && itm.order >= 2 && itm.order <= 7) {
          if (data.value === 'Ya') {
            itm.isShow = true
            itm.validation.required = true
          } else {
            itm.isShow = false
            itm.validation.required = false
          }
        }
        if (data.fields === 'is_divorce' && itm.order === 8) {
          if (data.value === 'Ya') {
            itm.isShow = true
            itm.validation.required = true
          } else {
            itm.isShow = false
            itm.validation.required = false
          }
        }
        else if (data.fields === 'is_have_child' && itm.order >= 9 && itm.order <= 14) {
          if (data.value === 'Ya') {
            itm.isShow = true
            itm.validation.required = true
          } else {
            itm.isShow = false
            itm.validation.required = false
          }
        }
      })
    }
    else if (data.parent === 'personal_spiritual_data' && data.fields === 'full_bible_read_amount') {
      data.value = parseInt(data.value)
    }
  },
  [ADD_FORM] (_state, data) {
    let len = _state.formRegist[data.parent].length + 1
    _state.formRegist[data.parent].map(itm => {
      if (itm.group === data.group) {
        let slice = -1
        if (itm.num >= 10) {
          slice = -2
        }
        const item = {...itm}
        item.order = len
        item.title = itm.title.slice(0, slice) + (itm.num+1)
        item.attr = itm.attr.slice(0, slice) + (itm.num+1)
        item.group = itm.group + 1
        item.num = itm.num + 1
        item.value = ''
        item.isDisabledRemove = false
        if (item.type === 'select') {
          item.value = item.option[0]
        }
        itm.isMultiData = false
        _state.formRegist[data.parent].push(item)
        len++
      }
    })
  },
  [REMOVE_FORM] (_state, data) {
    const filtered = []
    _state.formRegist[data.parent].map(itm => {
      if (itm.group !== data.group) {
        filtered.push(itm)
      }
      if (itm.group === data.group-1 && itm.isMultiData === false) {
        itm.isMultiData = true
      }
    })

    _state.formRegist[data.parent] = filtered
  },
  [INIT_FORM] (_state, params) {
    _state.formRegist = {}
    const data = params.forms
    const role = getConfig().role || 'mahasiswa'
    const registData = {..._state.registrationForm}

    registData.service_history_data = {
      list: registData.service_history_data
    }
    registData.job_history_data = {
      list: registData.job_history_data
    }
    registData.skill_data = {
      list: registData.skill_data
    }
    if (['mth', 'mdiv', 'mmin'].indexOf(params.prodi) !== -1) {
      registData.education_history_data = {
        list: registData.education_history_data
      }
    }
    Object.keys(registData).map(itm => {
      if (registData[itm] && typeof registData[itm] === 'object') {
        const registItem = Object.keys(registData[itm])
        if (registItem.length > 0) {
          registItem.map(item => {
            if(registData[itm][item]) {
              if (typeof registData[itm][item] !== 'object') {
                data[itm].map(async obj => {
                  if (obj.fields === item) {
                    if (obj.type === 'date') {
                      obj.value = registData[itm][item] > 0 ? moment(new Date(registData[itm][item]*1000)).format('YYYY-MM-DD') : ''
                    } else if (obj.type === 'select') {
                      obj.value = registData[itm][item] === true ? 'Ya' : registData[itm][item] === false || registData[itm][item] === null ? 'Tidak' : registData[itm][item]
                    } else if (obj.type === 'file') {
                      const res = await fetch(registData[itm][item])
                      const blobFile = await res.blob()
                      const ext = getUrl(registData[itm][item])
                      obj.value = new File([blobFile], `file.${ext}`)
                      obj.path = registData[itm][item]
                    } else {
                      obj.value = registData[itm][item]
                    }
                    obj.isDisabled = disabledFormField(_state.status, role)
                    if (obj.type === 'select' && obj.value === 'Ya') {
                      data[itm].map(objData => {
                        if (obj.fields === 'is_marriage' && objData.order >= 2 && objData.order <= 7) {
                          if (obj.value === 'Ya') {
                            objData.isShow = true
                            objData.validation.required = true
                          } else {
                            objData.isShow = false
                            objData.validation.required = false
                          }
                        }
                        if (obj.fields === 'is_divorce' && objData.order === 8) {
                          if (obj.value === 'Ya') {
                            objData.isShow = true
                            objData.validation.required = true
                          } else {
                            objData.isShow = false
                            objData.validation.required = false
                          }
                        }
                        else if (obj.fields === 'is_have_child' && objData.order >= 11 && objData.order <= 14) {
                          if (obj.value === 'Ya') {
                            objData.isShow = true
                            objData.validation.required = true
                          } else {
                            objData.isShow = false
                            objData.validation.required = false
                          }
                        }
                      })
                    }
                  }
                })
              } else {
                const multi = ['marriage_data', 'service_history_data', 'job_history_data', 'skill_data']
                if (['mdiv', 'mmin'].indexOf(params.prodi) !== -1) {
                  multi.push('education_history_data')
                }
                if (itm === 'family_data') {
                  registData[itm][item].map((obj, idx) => {
                    Object.keys(obj).map(objlist => {
                      data[itm].map(objData => {
                        if (objData.subfields === objlist && objData.num === idx + 1) {
                          if (objData.type === 'date') {
                            objData.value = obj[objlist] > 0 ? moment(new Date(obj[objlist] * 1000)).format('YYYY-MM-DD') : ''
                          } else {
                            objData.value = obj[objlist]
                          }
                          objData.isDisabled = disabledFormField(_state.status, role)
                        }
                      })
                    })
                  })
                } else if (itm === 'education_history_data' && ['sert'].indexOf(params.prodi) !== -1) {
                  let attr = ''
                  Object.keys(registData[itm][item]).map((objlist) => {
                    if (objlist === 'attr') {
                      attr = registData[itm][item][objlist]
                    } else {
                      data[itm].map(objData => {
                        if (objData.attr === attr && objData.subfields === objlist) {
                          if (objData.type === 'date' || objData.type === 'year') {
                            objData.value = registData[itm][item][objlist] > 0 ? moment(new Date(registData[itm][item][objlist] * 1000)).format('YYYY-MM-DD') : ''
                          } else {
                            if (registData[itm][item][objlist]) {
                              objData.value = registData[itm][item][objlist]
                            }
                          }
                          objData.isDisabled = disabledFormField(_state.status, role)
                        }
                      })
                    }
                  })
                } else if (multi.indexOf(itm) !== -1){
                  let lastObj = {}
                  registData[itm][item].map((obj, idx) => {
                    let attr = ''
                    if (idx >= 1) {
                      let len = data[itm].length + 1
                      data[itm].map(itmData => {
                        if (itmData.group === lastObj.group) {
                          const item = {...itmData}
                          item.order = len
                          item.title = itmData.title.slice(0, -1) + (itmData.num+1)
                          item.attr = itmData.attr.slice(0, -1) + (itmData.num+1)
                          item.group = itmData.group + 1
                          item.num = itmData.num + 1
                          item.value = ''
                          item.isDisabledRemove = false
                          item.isShow = true
                          item.isMultiData = true
                          if (item.type === 'select') {
                            item.value = item.option[0]
                          }
                          itmData.isMultiData = false
                          data[itm].push(item)
                          len++
                        }
                      })
                    }
                    Object.keys(obj).map((objlist) => {
                      if (objlist === 'attr') {
                        attr = obj[objlist]
                      } else {
                        data[itm].map(objData => {
                          if (objData.attr === attr && objData.subfields === objlist) {
                            if (objData.type === 'date' || objData.type === 'year') {
                              objData.value = obj[objlist] > 0 ? moment(new Date(obj[objlist] * 1000)).format('YYYY-MM-DD') : ''
                            } else {
                              objData.value = obj[objlist]
                            }
                            objData.isDisabled = disabledFormField(_state.status, role)
                            objData.isShow = true
                            lastObj = objData
                          }
                        })
                      }
                    })
                  })
                } else if (itm === 'personal_struggle_data' && params.prodi === 'sth') {
                  data[itm].length > 0 && data[itm].map(obj => {
                    if (obj.fields === 'addiction') {
                      obj.value = parseAddictionFrom(registData[itm][item])
                      obj.isDisabled = disabledFormField(_state.status, role)
                    }
                  })
                } else {
                  data[itm].length > 0 && data[itm].map(obj => {
                    if (obj.fields === item) {
                      obj.value = parseHeardFrom(registData[itm][item])
                      obj.isDisabled = disabledFormField(_state.status, role)
                    }
                  })
                }
              }
            } else if (registData[itm][item] === null 
              && ((['sth', 'mdiv', 'mthu'].indexOf(params.prodi) !== -1 && ['job_history_data', 'service_history_data', 'skill_data'].indexOf(itm) !== -1) 
                || (['mth', 'mmin'].indexOf(params.prodi) !== -1 && ['job_history_data', 'skill_data'].indexOf(itm) !== -1)
                || (['sert'].indexOf(params.prodi) !== -1 && ['job_history_data'].indexOf(itm) !== -1)
                )
              ) {
              data[itm].map(obj => {
                obj.isDisabled = disabledFormField(_state.status, role)
              })
            } else {
              data[itm] && data[itm].map(obj => {
                obj.isDisabled = disabledFormField(_state.status, role)
              })
            }
          })
        }
      } else if (registData[itm] === null && typeof data[itm] === 'object') {
        data[itm].map(obj => {
          if (!obj.isDisabled) {
            if (obj.type !== 'select' && obj.type !== 'file') {
              obj.value = ''
            } else if (obj.type === 'select') {
              obj.value = obj.option[0]
            } else if (obj.type === 'file') {
              obj.value = []
            }
          }
        })
      } else {
        if (registData[itm]) {
          data[itm] = registData[itm]
        }
      }
    })
    _state.formRegist = data
  },
  [INIT_FORM_COUPLE_AGREEMENT] (_state, data) {
    const coupleData = {..._state.couple_agreement_form}
    const role = getConfig().role || 'mahasiswa'
    Object.keys(coupleData).map(item => {
      data.couple_agreement_form.map(async itm => {
        if (item === itm.fields && coupleData[item]) {
          if (itm.type === 'date') {
            itm.value = coupleData[item] > 0 ? moment(new Date(coupleData[item]*1000)).format('YYYY-MM-DD') : ''
          } else if (itm.type === 'file') {
            const res = await fetch(coupleData[item])
            const blobFile = await res.blob()
            const ext = getUrl(coupleData[item])
            itm.value = new File([blobFile], `file.${ext}`)
            itm.path = coupleData[item]
          } else {
            itm.value = coupleData[item]
          }
        }
        itm.isDisabled = disabledFormField(_state.status, role)
      })
    })
    if (Object.keys(coupleData).length === 0) {
      data.couple_agreement_form.map(itm => itm.isDisabled = disabledFormField(_state.status, role))
    }
    _state.formCoupleAgreement = data
  },
  [INIT_FORM_HEALTH] (_state, data) {
    const healthData = {..._state.health_form}
    const role = getConfig().role || 'mahasiswa'
    healthData.health_related_file_url = {
      list: healthData.health_related_file_url
    }
    Object.keys(healthData).map(itm => {
      if (itm === 'health_form_url') {
        data[itm].map(async item => {
          if (item.fields === itm) {
            const res = await fetch(healthData[itm])
            const blobFile = await res.blob()
            const ext = getUrl(healthData[itm])
            item.value = healthData[itm] ? new File([blobFile], `file.${ext}`) : []
            item.path = healthData[itm] ? healthData[itm] : ''
            item.isDisabled = disabledFormField(_state.status, role)
          }
        })
      } else {
        if (healthData[itm] && typeof healthData[itm] === 'object') {
          const registItem = Object.keys(healthData[itm])
          if (registItem.length > 0) {
            registItem.map(item => {
              if(healthData[itm][item]) {
                const multi = ['health_related_file_url']
                if (multi.indexOf(itm) !== -1){
                  let lastObj = {}
                  healthData[itm][item].map((obj, idx) => {
                    let attr = ''
                    if (idx >= 1) {
                      let len = data[itm].length + 1
                      data[itm].map(itmData => {
                        if (itmData.group === lastObj.group) {
                          const item = {...itmData}
                          item.order = len
                          item.title = itmData.title.slice(0, -1) + (itmData.num+1)
                          item.attr = itmData.attr.slice(0, -1) + (itmData.num+1)
                          item.group = itmData.group + 1
                          item.num = itmData.num + 1
                          item.value = ''
                          item.isDisabledRemove = false
                          item.isShow = true
                          item.isMultiData = true
                          if (item.type === 'select') {
                            item.value = item.option[0]
                          }
                          if (item.type === 'file') {
                            item.path = ''
                          }
                          itmData.isMultiData = false
                          data[itm].push(item)
                          len++
                        }
                      })
                    }
                    Object.keys(obj).map((objlist) => {
                      if (objlist === 'attr') {
                        attr = obj[objlist]
                      } else {
                        data[itm].map(objData => {
                          if (objData.attr === attr && objData.subfields === objlist) {
                            if (objData.type === 'date' || objData.type === 'year') {
                              objData.value = obj[objlist] > 0 ? moment(new Date(obj[objlist] * 1000)).format('YYYY-MM-DD') : ''
                            } else if (objData.type === 'file'){
                              const ext = getUrl(obj[objlist])
                              objData.value = obj[objlist] ? new File([], `file.${ext}`) : []
                              objData.path = obj[objlist] ? obj[objlist] : ''
                            } else {
                              objData.value = obj[objlist]
                            }
                            objData.isDisabled = disabledFormField(_state.status, role)
                            objData.isShow = true
                            lastObj = objData
                          }
                        })
                      }
                    })
                  })
                }
              }
            })
          }
        } else if (healthData[itm] === null && typeof data[itm] === 'object') {
          data[itm].map(obj => {
            if (!obj.isDisabled) {
              if (obj.type !== 'select' && obj.type !== 'file') {
                obj.value = ''
              } else if (obj.type === 'select') {
                obj.value = obj.option[0]
              } else if (obj.type === 'file') {
                obj.value = []
              }
            }
          })
        }
      }
    })
    _state.formHealth = data
  },
  [INIT_FORM_FIN] (_state, data) {
    const role = getConfig().role || 'mahasiswa'
    const finData = {..._state.financial_form}
    Object.keys(finData).map(item => {
      data.financial_support_url.map(async itm => {
        if (item === itm.fields && finData[item]) {
          if (itm.type === 'file') {
            const res = await fetch(finData[item])
            const blobFile = await res.blob()
            const ext = getUrl(finData[item])
            itm.value = new File([blobFile], `file.${ext}`)
            itm.path = finData[item]
          } else {
            itm.value = finData[item]
          }
          itm.isDisabled = disabledFormField(_state.status, role)
        }
      })
    })
    _state.formFin = data
  },
  [ADD_FORM_HEALTH] (_state, data) {
    let len = _state.formHealth[data.parent].length + 1
    _state.formHealth[data.parent].map(itm => {
      if (itm.group === data.group) {
        const item = {...itm}
        item.order = len
        item.title = itm.title.slice(0, -1) + (itm.group+1)
        item.attr = itm.attr.slice(0, -1) + (itm.group+1)
        item.group = itm.group + 1
        item.value = ''
        item.path = ''
        item.isDisabledRemove = false
        if (item.type === 'select') {
          item.value = item.option[0]
        }
        if (item.type === 'file') {
          item.value = []
        }
        itm.isMultiData = false
        _state.formHealth[data.parent].push(item)
        len++
      }
    })
  },
  [REMOVE_FORM_HEALTH] (_state, data) {
    const filtered = []
    _state.formHealth[data.parent].map(itm => {
      if (itm.group !== data.group) {
        filtered.push(itm)
      }
      if (itm.group === data.group-1 && itm.isMultiData === false) {
        itm.isMultiData = true
      }
    })

    _state.formHealth[data.parent] = filtered
  },
  [ADD_FORM_COMP_EDU] (_state, data) {
    let len = _state.formFileCompletion.fileCompletionEdu.length
    const tempData = {...data}
    let slice = -2
    if (tempData.num >= 10) {
      slice = -3
    }
    tempData.value = []
    tempData.path = ''
    tempData.order = data.order + len
    tempData.num = data.num + len
    tempData.title = data.title.slice(0, slice) + (tempData.num) + ')'
    tempData.attr = data.attr.slice(0, slice) + (tempData.num)
    _state.formFileCompletion.fileCompletionEdu.push(tempData)
    len++
  },
  [REMOVE_FORM_COMP_EDU] (_state, data) {
    _state.formFileCompletion.fileCompletionEdu.pop()
  },
  [INIT_FORM_RECOMMENDATION](_state, data) {
    const recData = {..._state.recommendation_form}
    const role = getConfig().role || 'mahasiswa'
    Object.keys(recData).map(item => {
      data.recommendData.map(async itm => {
        if (item === itm.fields && recData[item]) {
          if (itm.type === 'file') {
            const res = await fetch(recData[item])
            const blobFile = await res.blob()
            const ext = getUrl(recData[item])
            itm.value = new File([blobFile], `file.${ext}`)
            itm.path = recData[item]
          } else {
            itm.value = recData[item]
          }
          itm.isDisabled = disabledFormField(_state.status, role)
        }
      })
    })
    _state.formRecommendation = data
  },
  [INIT_FORM_FILE_COMPLETION](_state, data) {
    const fileData = {..._state.completeness_data_form}
    const role = getConfig().role || 'mahasiswa'
    Object.keys(fileData).map(item => {
      data.fileCompletionData.map(async itm => {
        if (item === itm.fields && fileData[item]) {
          if (itm.type === 'file') {
            const res = await fetch(fileData[item])
            const blobFile = await res.blob()
            const ext = getUrl(fileData[item])
            itm.value = new File([blobFile], `file.${ext}`)
            itm.path = fileData[item]
          } else {
            itm.value = fileData[item]
          }
          itm.isDisabled = disabledFormField(_state.status, role)
        }
      })
    })
    // handling transcript
    let fileTranscript = []
    if (fileData.academic_transcript_url  && typeof fileData.academic_transcript_url  === 'string') {
      fileTranscript = [{
        attr: 'attr1',
        url: `${fileData.academic_transcript_url }`
      }]
    } else if (fileData.academic_transcript_url ) {
      fileTranscript = fileData.academic_transcript_url 
    }
    fileTranscript.map(async (itm, idx) => {
      if (idx === 0) {
        const res = await fetch(itm.url)
        const blobFile = await res.blob()
        const ext = getUrl(itm.url)
        data.fileCompletionEdu[idx].value = new File([blobFile], `file.${ext}`)
        data.fileCompletionEdu[idx].path = itm.url
      } else {
        const tempData = {...data.fileCompletionEdu[0]}
        const res = await fetch(itm.url)
        const blobFile = await res.blob()
        const ext = getUrl(itm.url)
        tempData.value = new File([blobFile], `file.${ext}`)
        tempData.path = itm.url
        tempData.order = data.fileCompletionEdu[0].order + idx
        tempData.num = data.fileCompletionEdu[0].num + idx
        tempData.title = data.fileCompletionEdu[0].title.slice(0, -1) + (tempData.num)
        tempData.attr = data.fileCompletionEdu[0].attr.slice(0, -1) + (tempData.num)
        data.fileCompletionEdu.push(tempData)
      }
    })
    _state.formFileCompletion = data
  },
  [SET_SELECTED_ACADEMIC_YEAR] (_state, data) {
    _state.selectedAcademicYear = data
  },
  [SET_ACADEMIC_YEAR] (_state, data) {
    const academic = []
    data.list.map(itm => {
      // if (itm.status === 'active') {
        const startDate = moment(new Date(itm.start_date * 1000)).locale('id').format('DD MMM YYYY')
        const endDate = moment(new Date(itm.end_date * 1000)).locale('id').format('DD MMM YYYY')
        itm.title = `${itm.season} (${startDate} - ${endDate})`
        academic.push(itm)
      // }
    })
    _state.academicYear = academic
  },
  [SET_COMPLETION_FORM] (_state, data) {
    _state.completionForm = data.completion_form
    _state.registrationForm = data.registration_form
    _state.repentance_story = get(data, 'repentance_story', '')
    _state.calling_faith_story = get(data, 'calling_faith_story', '')
    _state.motivation_story = get(data, 'motivation_story', '')
    _state.couple_agreement_form = get(data, 'couple_agreement_form', '')
    _state.academic_year_id = get(data, 'academic_year_id', '')
    _state.financial_form = get(data, 'financial_form', '')
    _state.health_form = get(data, 'health_form', '')
    _state.recommendation_form = get(data, 'recommendation_form', ''),
    _state.completeness_data_form = get(data, 'completeness_data_form', '')
    _state.payment_form = get(data, 'payment_form', '')
    _state.status = get(data, 'status', '')
    _state.rejected_reason = get(data, 'rejected_reason', '')
    _state.lecturer_notes = get(data, 'lecturer_review', [])
    _state.interview = get(data, 'interview', '')
    _state.recommendation_form_result = get(data, 'recommendation_form_result', [])
    const student_agreement_letter_url = get(data, 'student_agreement_letter_url', '')
    const student_agreement_data = JSON.parse(JSON.stringify(student_agreement_letter))
    if (student_agreement_letter_url) {
      const ext = getUrl(student_agreement_letter_url)
      fetch(student_agreement_letter_url).then(res => {
        res.blob().then(blobFile => {
          student_agreement_data[0].path = student_agreement_letter_url
          student_agreement_data[0].value = new File([blobFile], `file.${ext}`)
          student_agreement_data[0].isDisabled = true
          _state.student_agreement_letter = student_agreement_data[0]
        })
      })
    } else _state.student_agreement_letter = student_agreement_data[0]
  },
  [SET_STUDENT_DETAIL] (_state, data) {
    _state.studentDetail = get(data, 'user', {})
    _state.studentDetail.academic_year = moment(new Date(get(data, 'academic_year', 0)*1000)).locale('id').format('YYYY')
    _state.studentDetail.status_name = get(data, 'status_name', '')
    _state.studentDetail.season_name = get(data, 'season_name', '')
    _state.studentDetail.program_study = get(data, 'program_study', '')
  }
}
