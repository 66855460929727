<template>
  <div class="footer">
    <v-container v-if="isDashboard" class="footer-dashboard">
      <p>Copyrights @{{ getYear }}<br>Sekolah Tinggi Teologi Amanat Agung</p>
    </v-container>
    <div v-else class="footer-web">
      <v-container>
        <v-row no-gutters class="pt-8">
          <v-col
            cols="12"
            sm="12"
            md="5"
            lg="5"
          >
            <div class="footer-web__section">
              <v-img
                lazy-src="@/assets/imgs/logo-footer.png"
                height="150"
                width="150"
                src="@/assets/imgs/logo-footer.png"
                class="mb-5"></v-img>
                <p>Jl. Kedoya Raya No.18<br>Jakarta Barat 11520<br>DKI Jakarta<br>Telp.: +62 21 5835 7685<br>Fax.: +62 21 5819 375<br>WA:&nbsp;<a href="https://wa.me/6282221111377">+62 8222 1111 377<br></a>e-mail: <a href="mailto:sttaa@sttaa.ac.id">sttaa@sttaa.ac.id</a></p>
                <div>
                  <v-icon color="white" class="mx-3" @click="goToSocialMedia('fb')">mdi-facebook</v-icon>
                  <v-icon color="white" class="mx-3" @click="goToSocialMedia('twitter')">mdi-twitter</v-icon>
                  <v-icon color="white" class="mx-3" @click="goToSocialMedia('ig')">mdi-instagram</v-icon>
                </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="4"
            class="d-flex align-end mb-6 mt-12"
          >
            <div class="footer-web__section">
              <p>Nomor Rekening:<br>BCA cab. Green Garden, Jakarta<br>a/c 2533.200.800<br>a/n Yayasan Amanat Agung Indonesia</p>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="3"
            lg="3"
          >
            <div class="footer-web__section mt-5">
              <h2 class="mb-2"><strong>Program Studi</strong></h2>
              <ul class="mb-16">
                <li><span style="color: #ffffff;"><a style="color: #ffffff;" href="https://www.sttaa.ac.id/id/pendidikan/program-studi/sarjana-teologi-s-th">S1 Teologi (S.Th.) </a></span></li>
                <!-- <li><span style="color: #ffffff;"><a style="color: #ffffff;" href="https://www.sttaa.ac.id/id/pendidikan/program-studi/magister-divinitas-m-div">Divinitas (M.Div.)</a></span></li> -->
                <li><span style="color: #ffffff;"><a style="color: #ffffff;" href="https://www.sttaa.ac.id/id/pendidikan/program-studi/magister-ministri-m-min">Ministri (M.Min.) </a></span></li>
                <li><span style="color: #ffffff;"><a style="color: #ffffff;" href="https://www.sttaa.ac.id/id/pendidikan/program-studi/magister-teologi-m-th">S2 Teologi (M.Th.) </a></span></li>
                <li><span style="color: #ffffff;"><a style="color: #ffffff;" href="https://www.sttaa.ac.id/id/pendidikan/program-studi/magister-teologi-m-th/program-studi-magister-teologi-m-th-untuk-s1-umum">S2 Teologi (M.Th.) untuk S1 Umum </a></span></li>
                <li><span style="color: #ffffff;"><a style="color: #ffffff;" href="https://www.sttaa.ac.id/id/pendidikan/program-studi/magister-teologi-m-th">Sertifikat Teologi</a></span></li>
              </ul>
              <p class="pt-7 mb-0"><span style="color: #ffffff;">Copyrights © {{ getYear }}<br>Sekolah Tinggi Teologi Amanat Agung</span></p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-btn
      v-show="!isDashboard"
      fab
      dark
      fixed
      bottom
      right
      color="green"
      @click="goToWhatsApp"
    >
      <v-icon>mdi-whatsapp</v-icon>
    </v-btn>
  </div>
</template>

<script>
  import {
    NAV_DASHBOARD,
  } from '@/constants/pages'
  export default {
    name: 'Footer',
    computed: {
      isDashboard () {
        const name = this.$route.name || ''
        return NAV_DASHBOARD.indexOf(name) !== -1
      },
      getYear () {
        return new Date().getFullYear()
      }
    },
    methods: {
      goToSocialMedia (type) {
        let url = 'https://www.facebook.com/sttamanatagung/'
        if (type === 'ig') {
          url = 'https://www.instagram.com/sttamanatagung/'
        } else if (type === 'twitter') {
          url = 'https://twitter.com/sttamanatagung?lang=id%22'
        }
        window.open(
          url,
          '_blank' // <- This is what makes it open in a new window.
        )
      },
      goToWhatsApp () {
        window.open('https://wa.me/6282221111377', '_blank')
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.footer {
  .footer-dashboard {
    text-align: center;
    p {
      font-size: 14px;
    }
  }
  .footer-web {
    background-image: url("../assets/imgs/bg-footer.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    min-height: 462px;
    &__section {
      ul {
        list-style-type: none;
        padding: 0;
        li {
          a {
            font-size: 14px;
            text-decoration: none;
          }
        }
      }
      h2 {
        color: white;
      }
      p {
        font-size: 14px;
        color: white;
        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .footer-web {
      background-image: none;
      background-color: #1E355E;
      padding-left: 4px;
      &__section {
        margin-bottom: 30px;
      }
    }
  }
}
</style>