// WEB
export const WEB_HOME = 'webHome'
export const LOGIN = 'login'
export const REGISTER = 'register'
export const FORGOT_PASSWORD = 'forgotPassword'
export const PROGRAM_STUDY = 'programStudy'
export const REGISTRATION_STATUS = 'registrationStatus'
export const REGISTRATION_DETAIL = 'registrationDetail'
export const REGISTRATION_FORM = 'registrationForm'
export const REGISTRATION_SHEET = 'registrationSheet'
export const PAYMENT = 'payment'
export const MY_PROFILE = 'myProfile'
export const CHANGE_PASSWORD = 'changePassword'
export const RECOMMENDATION = 'recommendation'


// DASHBOARD
export const DASHBOARD_LOGIN = 'dashboardLogin'
export const DASHBOARD_FORGOT_PASSWORD = 'dashboardForgotPassword'
export const REFERENCE_LIST = 'referenceList'
export const FORM_LIST = 'formList'
export const DETAIL= 'detail'
export const DASH_DETAIL_REGIST = 'dashboardDetailRegist'
export const STUDENT_DETAIL_FORM = 'studentDetailForm'
export const STUDENT_DETAIL_SHEET = 'studentDetailSheet'
export const STUDENT_DETAIL_PAYMENT = 'studentDetailPayment'
export const EVALUATION_SETTING = 'evaluationSetting'
export const REPORT = 'report'
export const LABEL = 'label'
export const LABEL_DETAIL = 'labelDetail'
export const REGISTRATION_SETTING = 'registrationSetting'
export const USER_SETTING = 'userSetting'
export const ADD_USER = 'addUser'

export const ADMIN_PAGES = [
  REFERENCE_LIST,
  FORM_LIST,
  DETAIL,
  DASH_DETAIL_REGIST,
  STUDENT_DETAIL_FORM,
  STUDENT_DETAIL_SHEET,
  STUDENT_DETAIL_PAYMENT,
  EVALUATION_SETTING,
  REPORT,
  REGISTRATION_SETTING,
  USER_SETTING,
  ADD_USER,
  LABEL,
  LABEL_DETAIL
]

export const DOSEN_PAGES = [
  FORM_LIST,
  DETAIL,
]

export const STAFF_PAGES = [
  LABEL,
  LABEL_DETAIL
]

export const MAHASISWA_PAGES = [
  PROGRAM_STUDY,
  REGISTRATION_STATUS,
  REGISTRATION_DETAIL,
  REGISTRATION_FORM,
  REGISTRATION_SHEET,
  PAYMENT,
  MY_PROFILE,
  CHANGE_PASSWORD
]

export const NAV_DASHBOARD = [
  DASHBOARD_LOGIN,
  REFERENCE_LIST,
  FORM_LIST,
  DETAIL,
  DASH_DETAIL_REGIST,
  REPORT,
  LABEL,
  LABEL_DETAIL,
  REGISTRATION_SETTING,
  USER_SETTING,
  ADD_USER,
  DASHBOARD_FORGOT_PASSWORD,
  STUDENT_DETAIL_FORM,
  STUDENT_DETAIL_SHEET,
  STUDENT_DETAIL_PAYMENT,
  EVALUATION_SETTING,
]

export const HIDE_APPBAR = [
  DASHBOARD_LOGIN,
  DASHBOARD_FORGOT_PASSWORD
]
export const LOGIN_APPBAR = [
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  RECOMMENDATION
]

export const NEED_OAUTH = [
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  WEB_HOME,
  DASHBOARD_LOGIN,
  DASHBOARD_FORGOT_PASSWORD,
]