export const SET_FORM = 'setForm'
export const INIT_FORM = 'initForm'
export const ADD_FORM = 'addForm'
export const REMOVE_FORM = 'removeForm'

export const INIT_FORM_COUPLE_AGREEMENT = 'initFormCoupleAgreement'
export const INIT_FORM_HEALTH = 'initFormHealth'
export const INIT_FORM_FIN = 'initFormFin'
export const REMOVE_FORM_HEALTH = 'removeFormHealth'
export const ADD_FORM_HEALTH = 'addFormHealth'
export const INIT_FORM_RECOMMENDATION = 'initFormRecommendation'
export const INIT_FORM_FILE_COMPLETION = 'initFormFileCompletion'
export const SET_SELECTED_ACADEMIC_YEAR = 'setSelectedAcademicYear'
export const SET_ACADEMIC_YEAR = 'setAcademicYear'
export const SET_COMPLETION_FORM = 'setCompletionForm'
export const SET_STUDENT_DETAIL = 'setStudentDetail'
export const ADD_FORM_COMP_EDU = 'addFormCompEdu'
export const REMOVE_FORM_COMP_EDU = 'removeFormCompEdu'