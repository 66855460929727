import store from '@/store'
import appsNames from './appsNames'

const loadStoreModules = (path) => {
  let name = 'web'
  if (path.indexOf(appsNames[0]) !== -1) {
    name = appsNames[0]
  }
  const appsStore = import(
    /* webpackChunkName: "[request]" */
    `@/apps/${name}/store`
  )
  return new Promise((resolve) => {
    appsStore.then(st => {
      if(!store.state[name]) {
        store.registerModule(name, st.default)
      }
      resolve()
    }).catch(() => {
      resolve()
    })
  })
}

export default loadStoreModules
