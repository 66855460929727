<template>
  <div v-if="!isHideNavBar" class="app-bar">
    <div class="app-bar__header1 d-flex justify-center align-center">
      <v-container>
        <div class="app-bar__header1_inner d-flex align-center">
          <a href = "mailto: admisi@sttaa.ac.id">
            admisi@sttaa.ac.id
          </a>
        </div>
      </v-container>
    </div>
    <div class="app-bar__header2 d-flex align-center">
      <v-container 
        class="app-bar__header2_container d-flex flex-row align-center"
        :class="[isHideMenus && isMdState ? 'justify-space-between' : '']"
      >
        <div 
          class="app-bar__header2_title img_desktop"
          :class="[isHideMenus ? 'hidden-md-and-down' : 'hidden-sm-and-down']"
        >
          <img width="449" height="180" src="../assets/imgs/logo.png" alt="logo-desktop"/>
        </div>
        <div 
          class="app-bar__header2_title_mobile"
          :class="[isHideMenus ? 'hidden-lg-and-up' : 'hidden-md-and-up']"
        >
          <img width="434" height="141" src="../assets/imgs/logo-mobile.png" alt="logo-mobile"/>
        </div>
        <div 
          class="app-bar__header2_content"
          :class="[isHideMenus ? 'hidden-md-and-down' : 'hidden-sm-and-down']"
        >  
          <div
            v-if="isLoginNavbar"
            class="d-flex align-center px-4 nav-font"
            @click="goToHome()"
          >
            Beranda
          </div>
          <v-tabs
            v-else
            v-model="tab"
            class="d-flex align-center"
          >
            <v-tab
              v-for="item in navMenus"
              :key="item.path"
              :to="item.path"
              exact
            >
              <p>{{ item.name }}</p>
            </v-tab>
          </v-tabs>
        </div>
        <v-btn 
          color="primary"
          large
          class="ml-4"
          :class="[isHideMenus ? 'hidden-md-and-down' : 'hidden-sm-and-down']"
          @click="doLogin()"
        >
          {{ loginBtnText }}
        </v-btn>
        <v-app-bar-nav-icon
          @click="toogleDrawer()" 
          :class="[isHideMenus ? 'hidden-lg-and-up' : 'hidden-md-and-up']"
        ></v-app-bar-nav-icon>
      </v-container>
    </div>
    <!-- Add a navigation bar -->
    <div
      v-if="drawer"
    >
      <v-list
        nav
        dense
        class="pa-4 mt-1"
      >
        <v-list-item-group>
          <v-list-item v-for="(item) in navMenus" :key="item.path">
            <v-list-item-title @click="goToPage(item)">{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-btn 
          color="primary"
          large
          block
          @click="doLogin()"
        >
          {{loginBtnText}}
        </v-btn>
      </v-list>
    </div>
    <!-- Navigation bar ends -->
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import {
    WEB_HOME,
    LOGIN,
    HIDE_APPBAR,
    LOGIN_APPBAR,
    DASHBOARD_LOGIN
  } from '@/constants/pages'

  export default {
    name: 'AppBar',
    data() {
      return {
        drawer: false,
        tab: null,
        navMenus: '',
        isLogin: false
      }
    },
    watch: {
      async $route (to, from) {
        this.navMenus = await this.getNavMenus()
        this.navMenus.map(item => {
          if (to.path.indexOf(item.path) !== -1) {
            this.tab = item.path
          }
        })
      },
    },
    computed: {
      ...mapState({
        navDefault: (state) => state.navDefault || [],
        navStudent: (state) => state.navStudent || [],
        navAdmin: (state) => state.navAdmin || [],
        navDosen: (state) => state.navDosen || [],
        navStaff: (state) => state.navStaff || []
      }),
      isHideMenus () {
        return this.navMenus.length > 3
      },
      isMdState () {
        return window.innerWidth < 1264
      },
      isHideNavBar () {
        const name = this.$route.name || ''
        return HIDE_APPBAR.indexOf(name) !== -1
      },
      isLoginNavbar () {
        const name = this.$route.name || ''
        return LOGIN_APPBAR.indexOf(name) !== -1
      },
      loginBtnText () {
        if (this.isLogin) {
          return 'KELUAR'
        }
        return 'MASUK'
      },
    },
    methods: {
      async config () {
        return await JSON.parse(localStorage.getItem('feConfig')) || {}
      },
      async getNavMenus () {
        let menus = this.navDefault
        const config = await this.config()
        if (config.role === 'mahasiswa') {
          menus = this.navStudent
        } else if (config.role === 'admin') {
          menus = this.navAdmin
        } else if (config.role === 'dosen') {
          menus = this.navDosen
        } else if (config.role === 'staff_admin') {
          menus = this.navStaff
        }
        this.isLogin = config.isLogin
        return menus
      },
      goToPage (item) {
        this.drawer = false
        if (this.$route.name !== item.pathName) {
          this.$router.push({
            name: item.pathName
          }).catch(() => {})
        }
      },
      goToHome () {
        this.goToPage({
          pathName: WEB_HOME
        })
      },
      toogleDrawer () {
        this.drawer = !this.drawer
      },
      async doLogin () {
        this.drawer = false
        if (this.isLogin) {
          localStorage.removeItem('feConfig')
          let defaultPage = WEB_HOME
          if (this.$route.path.indexOf('dashboard') !== -1) {
            defaultPage = DASHBOARD_LOGIN
          }
          this.$router.replace({
            name: defaultPage,
          }).catch(() => {})
        } else {
          if (this.$route.name !== LOGIN) {
            this.$router.push({
              name: LOGIN
            }).catch(() => {})
          }
        }        
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.app-bar {
  position: relative;
  &__header1 {
    width: 100%;
    height: 40px;
    background-color: #1E355E;
    &_inner {
      width: 100%;
      justify-content: flex-end;
      a {
        color: white;
        text-decoration: unset;
        font-size: 14px;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    &__header1 {
      padding: 0px 4px;
      &_inner {
        justify-content: center;
      }
    }
    &__header2 {
      height: 160px !important;
      padding: 0px 4px;
      &_container {
        justify-content: space-between !important;
      }
    }
  }
  .img_desktop {
    height: 127px;
  }
  &__header2 {
    background-color: white !important;
    height: 100px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    &_container {
      justify-content: flex-end;
    }
    &_title {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      img {
        width: 100%;
        height: auto;
      }
    }
    &_title::after {
      content: url('https://www.sttaa.ac.id/templates/sj_wisdom/images/shadow2.png');
      margin-left: -1px;
    }
    &_title::before {
      content: '';
      display: inline-block;
      margin-right: -1px;
      width: 16px;
      height: 140px;
      background-color: white;
    }
    &_title_mobile {
      img {
        height: auto;
        width: 255px;
      }
    }
    &_content {
      height: 52px;
      .nav-font {
        font-size: 14px;
        letter-spacing: 0.0892857143em;
        font-weight: 500;
        height: -webkit-fill-available;
        &:hover{
          cursor: pointer;
        }
      }
      p {
        text-transform: capitalize;
        margin: 0;
      }
    }
  }
}
</style>