<template>
  <div class="dialog-session">
    <v-overlay :value="dialogSession">
      <v-card light class="dialog-session__card">
        <v-card-title class="text-h6">
          Sesi Anda Berakhir!
        </v-card-title>

        <v-card-text>
          Silakan melakukan proses masuk kembali.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="handleDialog()"
          >
            MASUK ULANG
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
import router from '@/router'
import { DASHBOARD_LOGIN, LOGIN } from '@/constants/pages'
  export default {
    name: 'DialogSession',
    data () {
      return {
        dialogSession: false,
      }
    },
    methods: {
      show (isRouting = false) {
        this.dialogSession = true
        if (isRouting) {
          setTimeout(() => {
              this.handleDialog()
          }, 3000)
        }
      },
      hide () {
        this.dialogSession = false
      },
      handleDialog() {
        this.hide()
        let defaultPage = LOGIN
        if (router.currentRoute.path.indexOf('dashboard') !== -1) {
          defaultPage = DASHBOARD_LOGIN
        }
        router.replace({
          name: defaultPage
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.dialog-session {
  &__card {
    width: 100%;
    max-width: 550px;
    margin-left: 16px;
    margin-right: 16px;
  }
}
</style>
