import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import id from 'vuetify/lib/locale/id'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#1E355E',
        secondary: '#BDBDBD',
        accent: '#F3F3F3',
        error: '#EC5F59',
        background: '#f3f3f3'
      },
    },
  },
  lang: {
    locales: { id },
    current: 'id',
  },
});
