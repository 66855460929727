// STH
export const personal_data = [{
  id: 'fullnamePersonalData',
  parent: 'personal_data',
  title: 'Nama Lengkap (Sesuai Akta Lahir)',
  fields: 'fullname',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 1 
}, {
  id: 'genderPersonalData',
  parent: 'personal_data',
  title: 'Jenis Kelamin',
  fields: 'gender',
  value: 'Perempuan',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Perempuan', 'Laki-laki'],
  order: 2 
}, {
  id: 'birthPlacePersonalData',
  parent: 'personal_data',
  title: 'Tempat Lahir',
  fields: 'birth_place',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 3
}, {
  id: 'birthDatePersonalData',
  parent: 'personal_data',
  title: 'Tanggal Lahir',
  fields: 'birth_date',
  value: '',
  validation: {
    required: true
  },
  type: 'date',
  menu: false,
  order: 4,
}, {
  id: 'nationalityPersonalData',
  parent: 'personal_data',
  title: 'Warganegara',
  fields: 'nationality',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 5
}, {
  id: 'idPassportPersonalData',
  parent: 'personal_data',
  title: 'NIK (WNI)',
  fields: 'id_passport_number',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 6
// }, {
//   id: 'naPassportPersonalData',
//   parent: 'personal_data',
//   title: 'Nomor Paspor (WNA, berikan tanda - atau 0 apabila Anda WNI)',
//   fields: 'na_passport_number',
//   value: '',
//   validation: {
//     required: true
//   },
//   type: 'text',
//   order: 7
}, {
  id: 'addressPersonalData',
  parent: 'personal_data',
  title: 'Alamat Lengkap',
  fields: 'address',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 7
}, {
  id: 'cityPersonalData',
  parent: 'personal_data',
  title: 'Kota/Provinsi',
  fields: 'city',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 8
// }, {
//   id: 'postalCodePersonalData',
//   parent: 'personal_data',
//   title: 'Kota/Kode Pos',
//   fields: 'postal_code',
//   value: '',
//   validation: {
//     required: true
//   },
//   type: 'text',
//   order: 10
// }, {
//   id: 'homePhonePersonalData',
//   parent: 'personal_data',
//   title: 'Nomor Telepon Rumah',
//   fields: 'home_phone',
//   value: '',
//   validation: {
//     required: false
//   },
//   type: 'text',
//   order: 11
}, {
  id: 'phonePersonalData',
  parent: 'personal_data',
  title: 'Nomor HP Pribadi',
  fields: 'phone',
  value: '',
  validation: {
    required: true,
    phoneNumber: true
  },
  type: 'number',
  order: 9
}, {
  id: 'emailPersonalData',
  parent: 'personal_data',
  title: 'Alamat e-mail',
  fields: 'email',
  value: '',
  validation: {
    required: true,
    email: true
  },
  type: 'text',
  order: 10
}, {
  id: 'nonResideNamePersonalData',
  parent: 'personal_data',
  title: 'Nama',
  fields: 'non_reside_name',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 11
}, {
  id: 'nonResidePhonePersonalData',
  parent: 'personal_data',
  title: 'Nomor HP',
  fields: 'non_reside_phone',
  value: '',
  validation: {
    required: true,
    phoneNumber: true
  },
  type: 'number',
  order: 12
}]
export const family_data = [{
  id: 'role1FamilyData',
  parent: 'family_data',
  title: 'Ayah/ Wali',
  fields: 'family_member',
  subfields: 'role',
  value: 'Ayah',
  type: 'select',
  option: ['Ayah', 'Wali'],
  isDisabled: true,
  validation: {
    required: true
  },
  default: 'Ayah',
  order: 1,
  attr: 'family1',
  num: 1,
}, {
  id: 'name1FamilyData',
  parent: 'family_data',
  title: 'Nama Ayah/ Wali',
  fields: 'family_member',
  subfields: 'name',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 2,
  attr: 'family1',
  num: 1,
}, {
  id: 'phone1FamilyData',
  parent: 'family_data',
  title: 'Nomor Telepon/ HP Ayah/ Wali',
  fields: 'family_member',
  subfields: 'phone',
  value: '',
  validation: {
    required: true,
    phoneNumber: true
  },
  type: 'number',
  order: 4,
  attr: 'family1',
  num: 1,
}, {
  id: 'job1FamilyData',
  parent: 'family_data',
  title: 'Pekerjaan Ayah/ Wali',
  fields: 'family_member',
  subfields: 'job',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 5,
  attr: 'family1',
  num: 1,
}, {
  id: 'role2FamilyData',
  parent: 'family_data',
  title: 'Ibu/ Wali',
  fields: 'family_member',
  subfields: 'role',
  value: 'Ibu',
  type: 'select',
  option: ['Ibu', 'Wali'],
  isDisabled: true,
  validation: {
    required: true
  },
  order: 1,
  attr: 'family2',
  num: 2,
}, {
  id: 'name2FamilyData',
  parent: 'family_data',
  title: 'Nama Ibu/ Wali',
  fields: 'family_member',
  subfields: 'name',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 2,
  attr: 'family2',
  num: 2,
}, {
  id: 'phone2FamilyData',
  parent: 'family_data',
  title: 'Nomor Telepon/ HP Ibu/ Wali',
  fields: 'family_member',
  subfields: 'phone',
  value: '',
  validation: {
    required: true,
    phoneNumber: true
  },
  type: 'number',
  order: 4,
  attr: 'family2',
  num: 2,
}, {
  id: 'job2FamilyData',
  parent: 'family_data',
  title: 'Pekerjaan Ibu/ Wali',
  fields: 'family_member',
  subfields: 'job',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 5,
  attr: 'family2',
  num: 2,
}, {
  id: 'birthOrderFamilyData',
  parent: 'family_data',
  title: 'Pendaftar Merupakan Anak ke',
  fields: 'birth_order',
  value: 1,
  validation: {
    required: true
  },
  type: 'select',
  option: [1,2,3,4,5,6,7,8,9,10],
  order: 1,
}, {
  id: 'totalSiblingFamilyData',
  parent: 'family_data',
  title: 'Dari __ Bersaudara',
  fields: 'total_sibling',
  value: 1,
  validation: {
    required: true
  },
  type: 'select',
  option: [1,2,3,4,5,6,7,8,9,10],
  order: 1,
}]
export const marriage_data = [{
  id: 'isMarriageData',
  parent: 'marriage_data',
  title: 'Apakah Anda sudah menikah?',
  fields: 'is_marriage',
  value: 'Tidak',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak', 'Ya'],
  order: 1,
  group: 1,
}, {
  id: 'isDivorceMarriageData',
  parent: 'marriage_data',
  title: 'Pernahkah bercerai?',
  fields: 'is_divorce',
  value: 'Tidak',
  validation: {
    required: false
  },
  type: 'select',
  option: ['Tidak', 'Ya'],
  group: 2,
  order: 1,
}]
export const health_history_data = [{
  id: 'isMentalIllness',
  parent: 'health_history_data',
  title: 'Apakah Anda pernah menderita gangguan jiwa atau masalah kejiwaan yang berat?',
  fields: 'is_mental_illness',
  value: 'Tidak',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak', 'Ya'],
  order: 1,
}, {
  id: 'mentalIllnessDesc',
  parent: 'health_history_data',
  title: 'Bila “ya”, mohon jelaskan secara ringkas. Bila Tidak, silakan isi dengan "-"',
  fields: 'mental_illness_desc',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 2,
}, {
  id: 'mentalIllnessFam',
  parent: 'health_history_data',
  title: 'Apakah ada anggota keluarga Anda yang mempunyai riwayat/masalah kejiwaan?',
  fields: 'mental_illness_family',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 3,
}, {
  id: 'IllnessDesc',
  parent: 'health_history_data',
  title: 'Apakah Anda pernah/sedang menderita penyakit tertentu, seperti jantung, paru-paru, ginjal, TBC, kanker, HIV-AIDS, hepatitis, epilepsi, Autoimun, Lambung/Mag, Asma?',
  fields: 'illness_desc',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 4,
}, {
  id: 'ExistIllnessDesc',
  parent: 'health_history_data',
  title: 'Berikan penjelasan mengenai kondisi Anda pada saat ini berkaitan dengan penyakit yang pernah/sedang diderita!',
  fields: 'exist_illness_desc',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 5,
}]
export const skill_data = [{
  id: 'skillDataKe-1',
  parent: 'skill_data',
  title: 'Keahlian Ke-1',
  subfields: 'name',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  isMultiData: true,
  isDisabledRemove: true,
  type: 'text',
  order: 1,
  group: 1,
  num: 1,
  attr: 'skill1',
}, {
  id: 'skillDataLevelKe-1',
  parent: 'skill_data',
  title: 'Level Kompetensi Ke-1',
  subfields: 'level',
  fields: 'list',
  value: 'Dasar',
  validation: {
    required: true
  },
  isMultiData: true,
  isDisabledRemove: true,
  option: ['Dasar', 'Lanjut', 'Menengah'],
  type: 'select',
  order: 2,
  group: 1,
  num: 1,
  attr: 'skill1',
}]
export const education_history_data = [{
  id: 'schoolNameSD',
  parent: 'education_history_data',
  title: 'Nama Sekolah SD',
  subfields: 'school_name',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 1,
  group: 1,
  attr: 'sd'
}, {
  id: 'schoolCitySD',
  parent: 'education_history_data',
  title: 'Kota SD',
  subfields: 'city',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 2,
  group: 1,
  attr: 'sd'
}, {
  id: 'schoolGradYearSD',
  parent: 'education_history_data',
  title: 'Tahun Kelulusan SD',
  subfields: 'graduation_year',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 3,
  group: 1,
  attr: 'sd'
}, {
  id: 'schoolNameSMP',
  parent: 'education_history_data',
  title: 'Nama Sekolah SMP',
  subfields: 'school_name',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 4,
  group: 2,
  attr: 'smp'
}, {
  id: 'schoolCitySMP',
  parent: 'education_history_data',
  title: 'Kota SMP',
  subfields: 'city',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 5,
  group: 2,
  attr: 'smp'
}, {
  id: 'schoolGradYearSMP',
  parent: 'education_history_data',
  title: 'Tahun Kelulusan SMP',
  subfields: 'graduation_year',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 6,
  group: 2,
  attr: 'smp'
}, {
  id: 'schoolNameSMA',
  parent: 'education_history_data',
  title: 'Nama Sekolah SMA',
  subfields: 'school_name',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 7,
  group: 3,
  attr: 'sma'
}, {
  id: 'schoolCitySMA',
  parent: 'education_history_data',
  title: 'Kota SMA',
  subfields: 'city',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 8,
  group: 3,
  attr: 'sma'
}, {
  id: 'schoolGradYearSMA',
  parent: 'education_history_data',
  title: 'Tahun Kelulusan SMA',
  subfields: 'graduation_year',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 9,
  group: 3,
  attr: 'sma'
}, {
  id: 'schoolNameSpecial',
  parent: 'education_history_data',
  title: 'Pendidikan Tinggi/Formal lainnya',
  subfields: 'school_name',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 10,
  group: 4,
  attr: 'spesial'
}, {
  id: 'schoolGradYearSpecial',
  parent: 'education_history_data',
  title: 'Tahun Kelulusan',
  subfields: 'graduation_year',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 11,
  group: 4,
  attr: 'spesial'
}]
export const full_education_history_data = [{
  id: 'schoolNameSD',
  parent: 'education_history_data',
  title: 'Nama Sekolah SD',
  subfields: 'school_name',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 1,
  group: 1,
  attr: 'sd'
}, {
  id: 'schoolCitySD',
  parent: 'education_history_data',
  title: 'Kota SD',
  subfields: 'city',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 2,
  group: 1,
  attr: 'sd'
}, {
  id: 'schoolGradYearSD',
  parent: 'education_history_data',
  title: 'Tahun Kelulusan SD',
  subfields: 'graduation_year',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 3,
  group: 1,
  attr: 'sd'
}, {
  id: 'schoolNameSMP',
  parent: 'education_history_data',
  title: 'Nama Sekolah SMP',
  subfields: 'school_name',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 4,
  group: 2,
  attr: 'smp'
}, {
  id: 'schoolCitySMP',
  parent: 'education_history_data',
  title: 'Kota SMP',
  subfields: 'city',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 5,
  group: 2,
  attr: 'smp'
}, {
  id: 'schoolGradYearSMP',
  parent: 'education_history_data',
  title: 'Tahun Kelulusan SMP',
  subfields: 'graduation_year',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 6,
  group: 2,
  attr: 'smp'
}, {
  id: 'schoolNameSMA',
  parent: 'education_history_data',
  title: 'Nama Sekolah SMA',
  subfields: 'school_name',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 7,
  group: 3,
  attr: 'sma'
}, {
  id: 'schoolCitySMA',
  parent: 'education_history_data',
  title: 'Kota SMA',
  subfields: 'city',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 8,
  group: 3,
  attr: 'sma'
}, {
  id: 'schoolGradYearSMA',
  parent: 'education_history_data',
  title: 'Tahun Kelulusan SMA',
  subfields: 'graduation_year',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 9,
  group: 3,
  attr: 'sma'
}, {
  id: 'schoolLevelDiploma',
  parent: 'education_history_data',
  title: 'Tingkat Pendidikan',
  subfields: 'education_level',
  fields: 'list',
  value: 'Diploma',
  validation: {
    required: false
  },
  type: 'select',
  option: ['Diploma', 'S1', 'S2', 'S3'],
  order: 10,
  group: 4,
  attr: '1'
}, {
  id: 'schoolNameDiploma',
  parent: 'education_history_data',
  title: 'Nama Sekolah',
  subfields: 'school_name',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 11,
  group: 4,
  attr: '1'
}, {
  id: 'schoolCityDiploma',
  parent: 'education_history_data',
  title: 'Kota Sekolah',
  subfields: 'city',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 12,
  group: 4,
  attr: '1'
}, {
  id: 'schoolGradeYearDiploma',
  parent: 'education_history_data',
  title: 'Tahun Kelulusan Sekolah',
  subfields: 'graduation_year',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 13,
  group: 4,
  attr: '1'
}, {
  id: 'schoolLevelS1',
  parent: 'education_history_data',
  title: 'Tingkat Pendidikan',
  subfields: 'education_level',
  fields: 'list',
  value: 'S1',
  validation: {
    required: false
  },
  type: 'select',
  option: ['Diploma', 'S1', 'S2', 'S3'],
  order: 14,
  group: 5,
  attr: '2'
}, {
  id: 'schoolNameS1',
  parent: 'education_history_data',
  title: 'Nama Sekolah',
  subfields: 'school_name',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 15,
  group: 5,
  attr: '2'
}, {
  id: 'schoolCityS1',
  parent: 'education_history_data',
  title: 'Kota Sekolah',
  subfields: 'city',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 16,
  group: 5,
  attr: '2'
}, {
  id: 'schoolGradeYearS1',
  parent: 'education_history_data',
  title: 'Tahun Kelulusan Sekolah',
  subfields: 'graduation_year',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 17,
  group: 5,
  attr: '2'
}, {
  id: 'schoolLevelS2',
  parent: 'education_history_data',
  title: 'Tingkat Pendidikan',
  subfields: 'education_level',
  fields: 'list',
  value: 'S2',
  validation: {
    required: false
  },
  type: 'select',
  option: ['Diploma', 'S1', 'S2', 'S3'],
  order: 18,
  group: 6,
  attr: '3'
}, {
  id: 'schoolNameS2',
  parent: 'education_history_data',
  title: 'Nama Sekolah',
  subfields: 'school_name',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 19,
  group: 6,
  attr: '3'
}, {
  id: 'schoolCityS2',
  parent: 'education_history_data',
  title: 'Kota Sekolah',
  subfields: 'city',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 20,
  group: 6,
  attr: '3'
}, {
  id: 'schoolGradeYearS2',
  parent: 'education_history_data',
  title: 'Tahun Kelulusan Sekolah',
  subfields: 'graduation_year',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 21,
  group: 6,
  attr: '3'
}, {
  id: 'schoolLevelS3',
  parent: 'education_history_data',
  title: 'Tingkat Pendidikan',
  subfields: 'education_level',
  fields: 'list',
  value: 'S3',
  validation: {
    required: false
  },
  type: 'select',
  option: ['Diploma', 'S1', 'S2', 'S3'],
  order: 22,
  group: 7,
  attr: '4'
}, {
  id: 'schoolNameS3',
  parent: 'education_history_data',
  title: 'Nama Sekolah',
  subfields: 'school_name',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 23,
  group: 7,
  attr: '4'
}, {
  id: 'schoolCityS3',
  parent: 'education_history_data',
  title: 'Kota Sekolah',
  subfields: 'city',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 24,
  group: 7,
  attr: '4'
}, {
  id: 'schoolGradeYearS3',
  parent: 'education_history_data',
  title: 'Tahun Kelulusan Sekolah',
  subfields: 'graduation_year',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 25,
  group: 7,
  attr: '4'
}, {
  parent: 'education_history_data',
  title: 'Pedidikan Khusus',
  subfields: 'school_name',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 26,
  group: 8,
  attr: 'spesial'
}, {
  parent: 'education_history_data',
  title: 'Tahun Kelulusan',
  subfields: 'graduation_year',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 27,
  group: 8,
  attr: 'spesial'
}]
export const job_history_data = [{
  id: 'jobHistoryPlaceKe-1',
  parent: 'job_history_data',
  title: 'Tempat Kerja Ke-1',
  subfields: 'place',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  isMultiData: true,
  isDisabledRemove: true,
  type: 'text',
  order: 1,
  group: 1,
  num: 1,
  attr: 'job1',
}, {
  id: 'jobHistoryPositionKe-1',
  parent: 'job_history_data',
  title: 'Jabatan/ Posisi Ke-1',
  subfields: 'position',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  isMultiData: true,
  isDisabledRemove: true,
  type: 'text',
  order: 2,
  group: 1,
  num: 1,
  attr: 'job1',
}, {
  parent: 'job_history_data',
  title: 'Tahun Mulai Bekerja Ke-1',
  subfields: 'period_start',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  isMultiData: true,
  isDisabledRemove: true,
  type: 'text',
  menu: false,
  order: 3,
  group: 1,
  num: 1,
  attr: 'job1',
}, {
  parent: 'job_history_data',
  title: 'Tahun Berakhir Bekerja Ke-1',
  subfields: 'period_end',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  isMultiData: true,
  isDisabledRemove: true,
  type: 'text',
  menu: false,
  order: 4,
  group: 1,
  num: 1,
  attr: 'job1',
}]
export const service_history_data = [{
  parent: 'service_history_data',
  title: 'Gereja Tempat Pelayanan Ke-1',
  subfields: 'church',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  isMultiData: true,
  isDisabledRemove: true,
  type: 'text',
  order: 1,
  num: 1,
  group: 1,
  attr: 'service1',
}, {
  parent: 'service_history_data',
  title: 'Bidang Pelayanan Ke-1',
  subfields: 'position',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  isMultiData: true,
  isDisabledRemove: true,
  type: 'text',
  order: 2,
  num: 1,
  group: 1,
  attr: 'service1',
}, {
  parent: 'service_history_data',
  title: 'Tahun Mulai Pelayanan Ke-1',
  subfields: 'period_start',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  isMultiData: true,
  isDisabledRemove: true,
  type: 'text',
  menu: false,
  order: 3,
  num: 1,
  group: 1,
  attr: 'service1',
}, {
  parent: 'service_history_data',
  title: 'Tahun Berakhir Pelayanan Ke-1',
  subfields: 'period_end',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  isMultiData: true,
  isDisabledRemove: true,
  type: 'text',
  menu: false,
  order: 4,
  num: 1,
  group: 1,
  attr: 'service1',
}]
export const church_member_data = [{
  id: 'churchMember',
  parent: 'church_member_data',
  title: 'Anggota Gereja',
  fields: 'church_member',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 1 
}, {
  id: 'churchAddressMember',
  parent: 'church_member_data',
  title: 'Alamat Gereja',
  fields: 'church_address',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 2
}, {
  id: 'churchCityMember',
  parent: 'church_member_data',
  title: 'Kota/ Provinsi Gereja',
  fields: 'church_city',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 3
}, {
  id: 'churchPhoneMember',
  parent: 'church_member_data',
  title: 'Telepon',
  fields: 'church_phone',
  value: '',
  validation: {
    required: false
  },
  type: 'number',
  order: 4
}, {
  id: 'churchEmailMember',
  parent: 'church_member_data',
  title: 'E-mail',
  fields: 'church_email',
  value: '',
  validation: {
    required: false,
    email: true
  },
  type: 'text',
  order: 5
}, {
  id: 'churchPastorMember',
  parent: 'church_member_data',
  title: 'Gembala/ Pendeta Senior',
  fields: 'church_senior_pastor',
  value: '',
  validation: {
    required: true,
  },
  type: 'text',
  order: 6
}, {
  id: 'churchActiveMember',
  parent: 'church_member_data',
  title: 'Sekarang Aktif di Gereja',
  fields: 'church_active',
  value: '',
  validation: {
    required: true,
  },
  type: 'text',
  order: 7
}]
export const financial_data = [{
  parent: 'financial_data',
  title: 'Yang memberikan dukungan finansial untuk biaya studi',
  fields: 'financial_data_answer',
  value: 'sendiri',
  validation: {
    required: true,
  },
  type: 'select',
  option: ['sendiri', 'donatur pribadi', 'keluarga','gereja/ institusi'],
  order: 1
}]
export const personal_spiritual_data = [{
  id: 'isSilentTime',
  parent: 'personal_spiritual_data',
  title: 'Apakah Anda memiliki waktu teduh pribadi bersama Allah secara teratur?',
  fields: 'is_silent_time',
  value: 'Tidak',
  validation: {
    required: true,
  },
  type: 'select',
  option: ['Ya', 'Tidak'],
  order: 1
}, {
  id: 'spirituallyNumber',
  parent: 'personal_spiritual_data',
  title: 'Bila dinilai dengan 1 (terendah) – 10 (tertinggi), berapa nilai kehidupan rohani Anda?',
  fields: 'spiritually_number',
  value: 1,
  validation: {
    required: true,
  },
  type: 'select',
  option: [1,2,3,4,5,6,7,8,9,10],
  order: 2
}, {
  id: 'fullBibleReadAmount',
  parent: 'personal_spiritual_data',
  title: 'Sudah berapa kali Anda membaca Alkitab secara utuh dari awalsampai akhir?',
  fields: 'full_bible_read_amount',
  value: 1,
  validation: {
    required: true,
  },
  type: 'number',
  order: 3
}, {
  id: 'isSpiritualFriend',
  parent: 'personal_spiritual_data',
  title: 'Apakah Anda memiliki sahabat rohani atau mentor?',
  fields: 'is_spiritual_friend',
  value: 'Tidak',
  validation: {
    required: true,
  },
  type: 'select',
  option: ['Ya', 'Tidak'],
  order: 4
}, {
  id: 'spiritualFriendName',
  parent: 'personal_spiritual_data',
  title: 'Jika Ada, sebutkan. (isi dengan - bila Tidak)',
  fields: 'spiritual_friend_name',
  value: '-',
  validation: {
    required: true,
  },
  type: 'text',
  order: 5
}]
export const personal_struggle_data = [{
  id: 'personalAddict',
  parent: 'personal_struggle_data',
  title: 'Apakah Anda pernah atau masih mengalami kecanduan.',
  fields: 'addiction',
  value: [],
  validation: {
    required: false,
  },
  option: [
    'Narkoba',
    'Gaming',
    'Alkohol',
    'Merokok',
    'Pornografi',
    'Judi',
    'Masturbasi',
    'Lain-lainnya'
  ],
  type: 'checkbox',
  order: 1
}, {
  parent: 'personal_struggle_data',
  title: 'Jika Lain-lainnya, sebutkan disini.',
  fields: 'other_desc',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 2,
}, {
  id: 'personalBadHabit',
  parent: 'personal_struggle_data',
  title: 'Sebutkan karakter atau kebiasaan buruk yang masih menjadi pergumulan Anda.',
  fields: 'bad_habit',
  value: '',
  validation: {
    required: true,
  },
  type: 'textArea',
  order: 3
}, {
  id: 'personalOvercomeEffort',
  parent: 'personal_struggle_data',
  title: 'Bagaimana usaha Anda untuk menyelesaikan atau mengatasinya?',
  fields: 'overcome_effort',
  value: '',
  validation: {
    required: true,
  },
  type: 'textArea',
  order: 4
}]
export const other_data = [{
  id: 'isEverRegisterSTT',
  parent: 'other_data',
  title: 'Apakah Anda pernah/sedang/akan mendaftar ke STT/Universitas lain?',
  fields: 'is_ever_register_stt',
  value: 'Tidak',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Ya', 'Tidak'],
  order: 1,
  group: 1,
}, {
  id: 'whenRegisterSTT',
  parent: 'other_data',
  title: 'Jika ya, Kapan?',
  fields: 'when_register_stt',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 2,
  group: 1,
}, {
  id: 'whichRegisterSTT',
  parent: 'other_data',
  title: 'STT/Universitas mana?',
  fields: 'which_register_stt',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 3,
  group: 1,
}, {
  id: 'isEverFinishSTT',
  parent: 'other_data',
  title: 'Apakah Anda pernah atau sedang atau telah menyelesaikan kuliah di STT atau Universitas lain?',
  fields: 'is_ever_finish_stt',
  value: 'Tidak',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Ya', 'Tidak'],
  order: 4,
  group: 1,
}, {
  id: 'heardFromWhere',
  parent: 'other_data',
  title: 'Darimana Anda mengetahui tentang STT Amanat Agung?',
  fields: 'heard_from_where',
  value: [],
  validation: {
    required: true
  },
  option: [
    'Alumni STT Amanat Agung.',
    'Hamba Tuhan/Rohaniwan.',
    'Teman.',
    'Dosen STT Amanat Agung.',
    'Mahasiswa STT Amanat Agung.',
    'Keluarga.',
    'Website resmi STT Amanat Agung.',
    'Media sosial STT Amanat Agung (Instagram, Twitter, Facebook, dll).',
    'Media komunikasi (WhatsApp Grup, Line, Telegram, dll).',
    'Poster, Flyer, Warta Gereja.',
    'Lain-lainnya'
  ],
  type: 'checkbox',
  order: 4,
  group: 2,
}, {
  id: 'otherDesc',
  parent: 'other_data',
  title: 'Jika Lain-lainnya, sebutkan disini.',
  fields: 'other_desc',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 5,
  group: 2,
}]
export const agreement_data = [{
  id: 'agreementDate',
  parent: 'agreement_data',
  title: 'Tanggal Menyatakan Persetujuan',
  fields: 'sign_date',
  value: '',
  validation: {
    required: true
  },
  type: 'date',
  order: 2
}, {
  id: 'agreementPict',
  parent: 'agreement_data',
  title: 'Tanda Tangan Anda (file PNG)',
  fields: 'sign_pict_url',
  value: [],
  validation: {
    required: true,
    accept: '.png, .jpg, .jpeg',
    isImg: true
  },
  type: 'file',
  order: 3
}, {
  id: 'parentAgreementName',
  parent: 'agreement_data',
  title: 'Nama Orang Tua/ Wali',
  fields: 'guardian_name',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 4
}, {
  id: 'parentAgreementPict',
  parent: 'agreement_data',
  title: 'Tanda Tangan Orang Tua/ Wali (file PNG)',
  fields: 'guardian_sign_pict_url',
  value: [],
  validation: {
    required: true,
    accept: '.png, .jpg, .jpeg',
    isImg: true
  },
  type: 'file',
  order: 5
}]
export const university_history_data = [{
  id: 'collegeUnivHistory',
  parent: 'education_history_data',
  title: 'Perguruan Tinggi ke-1',
  subfields: 'college',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  isMultiData: true,
  isDisabledRemove: true,
  type: 'text',
  order: 1,
  group: 1,
  num: 1,
  attr: 'university1'
}, {
  id: 'majorUnivHistory',
  parent: 'education_history_data',
  title: 'Program Study Ke-1',
  subfields: 'major_study',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  isMultiData: true,
  isDisabledRemove: true,
  type: 'text',
  order: 2,
  group: 1,
  num: 1,
  attr: 'university1'
}, {
  id: 'gradYearUnivHistory',
  parent: 'education_history_data',
  title: 'Tahun Kelulusan Ke-1',
  subfields: 'graduation_year',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  isMultiData: true,
  isDisabledRemove: true,
  type: 'text',
  order: 3,
  group: 1,
  num: 1,
  attr: 'university1'
}, {
  id: 'ipkUnivHistory',
  parent: 'education_history_data',
  title: 'IPK (0.0 - 4.0) Ke-1',
  subfields: 'ipk',
  fields: 'list',
  value: '',
  validation: {
    required: true
  },
  isMultiData: true,
  isDisabledRemove: true,
  type: 'number',
  order: 4,
  group: 1,
  num: 1,
  attr: 'university1'
}]

// Lembar Kesaksian Pertobatan
export const repentance_story = {
  parent: 'repentance_story',
  title: 'Tuliskan bagaimana Anda mengalami pertobatan dan meyakini akan keselamatan dalam Tuhan Yesus Kristus secara pribadi.',
  fields: 'repentance_story',
  value: '',
  validation: {
    required: true
  },
}
// Lembar Kesaksian Pertobatan
export const calling_faith_story = {
  parent: 'calling_faith_story',
  title: 'Tuliskan bagaimana Anda meyakini akan panggilan Allah, untuk menjadi hamba Tuhan secara penuh waktu.',
  fields: 'calling_faith_story',
  value: '',
  validation: {
    required: true
  },
}
// Lembar Kesaksian Pertobatan
export const motivation_story = {
  parent: 'motivation_story',
  title: 'Tuliskan apa yang menjadi motivasi dan tujuan Anda mendaftarkan diri belajar di STT Amanat Agung',
  fields: 'motivation_story',
  value: '',
  validation: {
    required: true
  },
}
export const couple_agreement_form = [{
  id: 'nameCoupleAgreement',
  parent: 'couple_agreement_form',
  title: 'Nama',
  fields: 'name',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 1,
  group: 1,
}, {
  id: 'addressCoupleAgreement',
  parent: 'couple_agreement_form',
  title: 'Alamat',
  fields: 'address',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 2,
  group: 1,
}, {
  id: 'cityCoupleAgreement',
  parent: 'couple_agreement_form',
  title: 'Kota/ Provinsi',
  fields: 'city',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 3,
  group: 1,
// }, {
//   id: 'postalCoupleAgreement',
//   parent: 'couple_agreement_form',
//   title: 'Kode Pos',
//   fields: 'postal_code',
//   value: '',
//   validation: {
//     required: true
//   },
//   type: 'text',
//   order: 4,
//   group: 1,
}, {
  id: 'phoneCoupleAgreement',
  parent: 'couple_agreement_form',
  title: 'Telepon/ Hp',
  fields: 'phone',
  value: '',
  validation: {
    required: true,
    phoneNumber: true
  },
  type: 'number',
  order: 5,
  group: 1,
}, {
  id: 'emailCoupleAgreement',
  parent: 'couple_agreement_form',
  title: 'E-mail',
  fields: 'email',
  value: '',
  validation: {
    required: true,
    email: true
  },
  type: 'text',
  order: 6,
  group: 1,
}, {
  id: 'relationCoupleAgreement',
  parent: 'couple_agreement_form',
  title: 'Hubungan dengan Calon Mahasiswa',
  fields: 'candidate_relation_status',
  value: 'suami',
  validation: {
    required: true
  },
  option: ['suami', 'istri'],
  type: 'select',
  order: 7,
  group: 1,
}, {
  id: 'candidateNameCoupleAgreement',
  parent: 'couple_agreement_form',
  title: 'Calon Mahasiswa Bernama',
  fields: 'candidate_name',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 8,
  group: 1,
// }, {
//   id: 'signPlaceCoupleAgreement',
//   parent: 'couple_agreement_form',
//   title: 'Tempat Menyatakan Persetujuan',
//   fields: 'sign_place',
//   value: '',
//   validation: {
//     required: true
//   },
//   type: 'text',
//   order: 9,
//   group: 2,
}, {
  id: 'signDateCoupleAgreement',
  parent: 'couple_agreement_form',
  title: 'Tanggal Menyatakan Persetujuan',
  fields: 'sign_date',
  value: '',
  validation: {
    required: true
  },
  type: 'date',
  order: 10,
  group: 2,
}, {
  id: 'signPictCoupleAgreement',
  parent: 'couple_agreement_form',
  title: 'Tanda Tangan Pasangan yang Menyatakan Persetujuan (file PNG)',
  fields: 'sign_pict_url',
  value: [],
  validation: {
    required: true,
    accept: '.png, .jpg, .jpeg',
    isImg: true
  },
  type: 'file',
  order: 11,
  group: 2,
}]
export const financial_support_url = [{
  id: 'financialSupportUrl',
  parent: 'financial_support_url',
  title: 'Pernyataan Dukungan Finansial (file PDF)',
  fields: 'financial_support_url',
  value: [],
  isFormPdf: true,
  validation: {
    required: true,
    maxSized: true,
    accept: '.pdf',
    isPdf: true
  },
  type: 'file',
  order: 1,
  group: 1,
}]
export const health_form_url = [{
  id: 'healthFormUrl',
  parent: 'health_form_url',
  title: 'Lembar Keterangan Kesehatan (file PDF)',
  fields: 'health_form_url',
  value: [],
  isFormPdf: true,
  validation: {
    required: true,
    maxSized: true,
    accept: '.pdf',
    isPdf: true
  },
  type: 'file',
  order: 1,
  group: 1,
}]
export const health_related_file_url = [{
  id: '',
  parent: 'health_related_file_url',
  title: 'Foto Terkait Kesehatan Ke-1',
  subfields: 'url',
  fields: 'list',
  value: [],
  validation: {
    required: true,
    maxSized: true,
    accept: '.png, .jpg, .jpeg',
    isImg: true
  },
  isMultiData: true,
  isDisabledRemove: true,
  type: 'file',
  order: 1,
  num: 1,
  group: 1,
  attr: 'health1',
}]

export const recommendationData = [{
  id: '',
  parent: 'recommendData',
  head: 'Formulir Rekomendasi A diisi oleh hamba Tuhan (di luar keluarga atau pacar) yang melayani di Gereja yang sama dengan Anda, dan telah mengenal Anda minimal 2 tahun. Mohon cantumkan nama, e-mail, dan No. HP Pemberi Rekomendasi A.',
  title: 'Nama Perekomendasi A',
  fields: 'name_a',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 1,
  group: 1,
  category: ['sth', 'sert']
},
{
  id: '',
  parent: 'recommendData',
  head: 'Formulir Rekomendasi A diisi oleh hamba Tuhan (di luar keluarga atau pacar) yang melayani di Gereja atau Lembaga Kristen yang sama dengan Anda, dan telah mengenal Anda minimal 2 tahun. Mohon cantumkan nama, e-mail, dan No. HP Pemberi Rekomendasi A.',
  title: 'Nama Perekomendasi A',
  fields: 'name_a',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 1,
  group: 1,
  category: ['mmin', 'mth', 'mdiv', 'mthu']
},
{
  id: '',
  parent: 'recommendData',
  head: 'Formulir Rekomendasi A diisi oleh hamba Tuhan (di luar keluarga atau pacar) yang melayani di Gereja atau Lembaga Kristen yang sama dengan Anda, dan telah mengenal Anda minimal 2 tahun. Mohon cantumkan nama, e-mail, dan No. HP Pemberi Rekomendasi A.',
  title: 'E-mail Perekomendasi A',
  fields: 'email_a',
  value: '',
  validation: {
    required: true,
    email: true
  },
  type: 'text',
  order: 2,
  group: 1,
  category: ['sth', 'mmin', 'sert', 'mth', 'mdiv', 'mthu']
}, {
  id: '',
  parent: 'recommendData',
  title: 'No.HP Perekomendasi A',
  head: 'Formulir Rekomendasi A diisi oleh hamba Tuhan (di luar keluarga atau pacar) yang melayani di Gereja atau Lembaga Kristen yang sama dengan Anda, dan telah mengenal Anda minimal 2 tahun. Mohon cantumkan nama, e-mail, dan No. HP Pemberi Rekomendasi A.',
  fields: 'phone_a',
  value: '',
  validation: {
    required: true,
    phoneNumber: true
  },
  type: 'number',
  order: 3,
  group: 1,
  category: ['sth', 'mmin', 'sert', 'mth', 'mdiv', 'mthu']
}, {
  id: '',
  parent: 'recommendData',
  title: 'Nama Perekomendasi B',
  head: 'Formulir Rekomendasi B diisi oleh teman atau sahabat (di luar keluarga atau pacar) yang telah mengenal Anda minimal 2 tahun. Mohon cantumkan nama, e-mail, dan No. HP Pemberi Rekomendasi B.',
  fields: 'name_b',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 4,
  group: 2,
  category: ['sth', 'mth', 'mdiv', 'mthu']
}, {
  id: '',
  parent: 'recommendData',
  title: 'E-mail Perekomendasi B',
  head: 'Formulir Rekomendasi B diisi oleh teman atau sahabat (di luar keluarga atau pacar) yang telah mengenal Anda minimal 2 tahun. Mohon cantumkan nama, e-mail, dan No. HP Pemberi Rekomendasi B.',
  fields: 'email_b',
  value: '',
  validation: {
    required: true,
    email: true
  },
  type: 'text',
  order: 5,
  group: 2,
  category: ['sth', 'mth', 'mdiv', 'mthu']
}, {
  id: '',
  parent: 'recommendData',
  title: 'No.HP Perekomendasi B' ,
  head: 'Formulir Rekomendasi B diisi oleh teman atau sahabat (di luar keluarga atau pacar) yang telah mengenal Anda minimal 2 tahun. Mohon cantumkan nama, e-mail, dan No. HP Pemberi Rekomendasi B.',
  fields: 'phone_b',
  value: '',
  validation: {
    required: true,
    phoneNumber: true
  },
  type: 'number',
  order: 6,
  group: 2,
  category: ['sth', 'mth', 'mdiv', 'mthu']
}, {
  id: '',
  parent: 'recommendData',
  title: 'Nama Perekomendasi C',
  head: 'Formulir Rekomendasi C diisi oleh dosen institusi sebelumnya yang mengenal Anda minimal 2 tahun. Mohon cantumkan nama, e-mail, dan No. HP Pemberi Rekomendasi C.',
  fields: 'name_c',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 7,
  group: 3,
  category: ['mth']
}, {
  id: '',
  parent: 'recommendData',
  title: 'E-mail Perekomendasi C',
  head: 'Formulir Rekomendasi C diisi oleh dosen institusi sebelumnya yang mengenal Anda minimal 2 tahun. Mohon cantumkan nama, e-mail, dan No. HP Pemberi Rekomendasi C.',
  fields: 'email_c',
  value: '',
  validation: {
    required: true,
    email: true
  },
  type: 'text',
  order: 8,
  group: 3,
  category: ['mth']
}, {
  id: '',
  parent: 'recommendData',
  title: 'No.HP Perekomendasi C',
  head: 'Formulir Rekomendasi C diisi oleh dosen institusi sebelumnya yang mengenal Anda minimal 2 tahun. Mohon cantumkan nama, e-mail, dan No. HP Pemberi Rekomendasi C.',
  fields: 'phone_c',
  value: '',
  validation: {
    required: true,
    phoneNumber: true
  },
  type: 'number',
  order: 9,
  group: 3,
  category: ['mth']
}]
export const fileCompletionData = [{
  id: '',
  parent: 'fileCompletionData',
  title: 'Pasfoto berwarna (file PNG)',
  fields: 'self_pict_url',
  value: [],
  validation: {
    required: true,
    maxSized: true,
    accept: '.png, .jpg, .jpeg',
    isImg: true
  },
  type: 'file',
  order: 1,
  group: 1,
  prodi: ['sth', 'mth', 'mdiv', 'mmin', 'sert', 'mthu']
}, {
  id: '',
  parent: 'fileCompletionData',
  title: 'Foto Akta Lahir ',
  fields: 'birth_certificate_url',
  value: [],
  validation: {
    required: true,
    maxSized: true,
    accept: '.png, .jpg, .jpeg',
    isImg: true,
  },
  type: 'file',
  order: 2,
  group: 1,
  prodi: ['mdiv', 'sert']
}, {
  id: '',
  parent: 'fileCompletionData',
  title: 'Foto KTP/ Kartu Keluarga',
  fields: 'id_card_url',
  value: [],
  validation: {
    required: true,
    maxSized: true,
    accept: '.png, .jpg, .jpeg',
    isImg: true
  },
  type: 'file',
  order: 3,
  group: 1,
  prodi: ['sth', 'mth', 'mdiv', 'mmin', 'sert', 'mthu']
}, {
  id: '',
  parent: 'fileCompletionData',
  title: 'Foto Surat Baptis Dewasa/ Sidi',
  fields: 'baptism_certificate_url',
  value: [],
  validation: {
    required: true,
    maxSized: true,
    accept: '.png, .jpg, .jpeg',
    isImg: true
  },
  type: 'file',
  order: 4,
  group: 1,
  prodi: ['sth', 'mdiv', 'mmin', 'sert', 'mthu']
}, {
  id: '',
  parent: 'fileCompletionData',
  title: 'Surat Keterangan dari Gereja/ Lembaga Pengutus (file PDF atau DOC)',
  fields: 'institution_decription_url',
  isFormPdf: true,
  value: [],
  validation: {
    required: true,
    maxSized: true,
    accept: '.pdf, .doc, .docx',
    isFile: true
  },
  type: 'file',
  order: 5,
  group: 1,
  prodi: ['sth', 'mth', 'mdiv', 'mmin', 'sert', 'mthu']
}, {
  id: '',
  parent: 'fileCompletionData',
  title: 'Sertifikat TOEFL/ IELTS (file PDF atau DOC)',
  fields: 'toefl_prediction_url',
  isFormPdf: true,
  value: [],
  validation: {
    required: true,
    maxSized: true,
    accept: '.pdf, .doc, .docx',
    isFile: true
  },
  type: 'file',
  order: 6,
  group: 1,
  prodi: ['mth', 'mmin', 'mthu']
}, {
  id: '',
  parent: 'fileCompletionData',
  title: 'Esai untuk Ujian Masuk M.Th (file PDF atau DOC)',
  fields: 'article_paper_url',
  isFormPdf: true,
  value: [],
  validation: {
    required: true,
    maxSized: true,
    accept: '.pdf, .doc, .docx',
    isFile: true
  },
  type: 'file',
  order: 7,
  group: 1,
  prodi: ['mth']
}, {
  id: '',
  parent: 'fileCompletionData',
  title: 'Foto Ijazah yang telah dilegalisir dari pendidikan terakhir',
  fields: 'education_certificate_url',
  value: [],
  validation: {
    required: true,
    maxSized: true,
    accept: '.png, .jpg, .jpeg',
    isImg: true
  },
  type: 'file',
  order: 8,
  group: 1,
  prodi: ['sth', 'mth', 'mdiv', 'mmin', 'sert', 'mthu']
}]

export const transcript_edu = [{
  id: '',
  parent: 'fileCompletionData',
  title: 'Foto transkrip nilai/ nilai rapor yang telah dilegalisir dari pendidikan terakhir (file ke-1)',
  fields: 'academic_transcript_url',
  value: [],
  validation: {
    required: true,
    maxSized: true,
    accept: '.png, .jpg, .jpeg',
  },
  isMultiData: true,
  num: 1,
  attr: 'academic_transcript_url-1',
  isDisabledRemove: true,
  type: 'file',
  order: 1,
  group: 1,
  prodi: ['sth', 'sert'],
  path: ''
}, {
  id: '',
  parent: 'fileCompletionData',
  title: 'Foto transkrip nilai yang telah dilegalisir dari pendidikan terakhir (file ke-1)',
  fields: 'academic_transcript_url',
  value: [],
  validation: {
    required: true,
    maxSized: true,
    accept: '.png, .jpg, .jpeg',
    isImg: true,
  },
  isMultiData: true,
  num: 1,
  attr: 'academic_transcript_url-1',
  isDisabledRemove: true,
  type: 'file',
  order: 1,
  group: 1,
  prodi: ['mth', 'mdiv', 'mmin', 'mthu'],
  path: ''
}]

export const student_agreement_letter = [{
  id: '',
  parent: 'student_agreement_letter_url',
  title: 'Surat Pernyataan Persetujuan Mahasiswa (file PDF atau DOC)',
  fields: 'student_agreement_letter_url',
  value: [],
  isFormPdf: true,
  validation: {
    required: true,
    maxSized: true,
    accept: '.pdf, .docx, .doc',
    isFile: true
  },
  type: 'file',
  order: 1,
  group: 1,
}]