export const INIT = 'init'
export const FORM_PENDING = 'form_pending'
export const FORM_COMPLETE = 'form_complete'
export const VALIDATE_PENDING = 'validate_pending'
export const VALIDATE_COMPLETE = 'validate_complete'
export const PASSED = 'passed'
export const STUDENT_AGREE = 'student_agree'
export const ACCEPTED = 'accepted'
export const REJECTED = 'rejected'
export const ALUMNI = 'alumni'
export const DROPOUT = 'dropout'