<template>
  <v-app id="inspire"
    :style="{background: $vuetify.theme.themes.light.background}"
    :class="{'app__bg_img': isWithoutNav}"
  >
    <app-bar />
    <v-main> 
      <router-view></router-view>
    </v-main>
    <footer-bar />
  </v-app>
</template>

<script>
import AppBar from '@/components/AppBar'
import FooterBar from '@/components/Footer'
import {
  HIDE_APPBAR
} from '@/constants/pages'
export default {
  name: 'App',
  components: {
    AppBar,
    FooterBar,
  },
  computed: {
    isWithoutNav () {
      const name = this.$route.name || ''
      return HIDE_APPBAR.indexOf(name) !== -1
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.tox-notification { display: none !important }
.app__bg_img {
  background-image: url('./assets/imgs/bg-login.png') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  min-height: 100vh;
}
@media #{map-get($display-breakpoints, 'md-and-down')} {
  .app__bg_img {
    background-image: unset !important;
  }
}
</style>
