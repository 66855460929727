import {
  WEB_HOME,
  PROGRAM_STUDY,
  REGISTRATION_STATUS,
  REGISTRATION_DETAIL,
  REGISTRATION_FORM,
  REGISTRATION_SHEET,
  MY_PROFILE,
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  PAYMENT,
  RECOMMENDATION
} from '@/constants/pages'
const Home = () => import(/* webpackChunkName: "web-home" */ '@/apps/web/views/home')
const Login = () => import(/* webpackChunkName: "web-login" */ '@/apps/web/views/login.vue')
const Register = () => import(/* webpackChunkName: "web-register" */ '@/apps/web/views/register.vue')
const ForgotPassword = () => import(/* webpackChunkName: "web-register" */ '@/apps/web/views/forgotPassword.vue')
const ProgramStudy = () => import(/* webpackChunkName: "web-program-study" */ '@/apps/web/views/programStudy')
const RegistrationStatus = () => import(/* webpackChunkName: "web-registration-status" */ '@/apps/web/views/registration/registrationStatus')
const RegistrationDetail = () => import(/* webpackChunkName: "web-registration-detail" */ '@/apps/web/views/registration/registrationDetail')
const RegistrationForm = () => import(/* webpackChunkName: "web-registration-form" */ '@/apps/web/views/registration/registrationForm')
const RegistrationSheet = () => import(/* webpackChunkName: "web-registration-sheet" */ '@/apps/web/views/registration/registrationSheet')
const Payment = () => import(/* webpackChunkName: "web-payment" */ '@/apps/web/views/registration/payment')
const MyProfile = () => import(/* webpackChunkName: "web-my-profile" */ '@/apps/web/views/myProfile')
const ChangePassword = () => import(/* webpackChunkName: "web-change-password" */ '@/apps/web/views/changePassword')
const Recommendation = () => import(/* webpackChunkName: "web-recommendation" */ '@/apps/web/views/recommendation')

export default [
  {
    path: '/',
    name: WEB_HOME,
    component: Home,
    meta: { 
      title: 'Beranda | STT Amanat Agung'
    }
  },
  {
    path: '/login',
    name: LOGIN,
    component: Login,
    meta: { 
      title: 'Login | STT Amanat Agung'
    }
  },
  {
    path: '/register',
    name: REGISTER,
    component: Register,
    meta: { 
      title: 'Register | STT Amanat Agung'
    }
  },
  {
    path: '/forgot-password',
    name: FORGOT_PASSWORD,
    component: ForgotPassword,
    meta: { 
      title: 'Forgot Password | STT Amanat Agung'
    }
  },
  {
    path: '/program-studi',
    name: PROGRAM_STUDY,
    component: ProgramStudy,
    meta: { 
      title: 'Program Studi | STT Amanat Agung'
    }
  },
  {
    path: '/status-pendaftaran',
    name: REGISTRATION_STATUS,
    component: RegistrationStatus,
    meta: { 
      title: 'Status Pendaftaran | STT Amanat Agung'
    }
  },
  {
    path: '/status-pendaftaran/detail',
    name: REGISTRATION_DETAIL,
    component: RegistrationDetail,
    meta: { 
      title: 'Detail Pendaftaran | STT Amanat Agung'
    }
  },
  {
    path: '/status-pendaftaran/detail/form',
    name: REGISTRATION_FORM,
    component: RegistrationForm,
    meta: { 
      title: 'Detail Pendaftaran | STT Amanat Agung'
    }
  },
  {
    path: '/status-pendaftaran/detail/sheet',
    name: REGISTRATION_SHEET,
    component: RegistrationSheet,
    meta: { 
      title: 'Detail Pendaftaran | STT Amanat Agung'
    }
  },
  {
    path: '/status-pendaftaran/detail/payment',
    name: PAYMENT,
    component: Payment,
    meta: { 
      title: 'Pembayaran | STT Amanat Agung'
    }
  },
  {
    path: '/profil',
    name: MY_PROFILE,
    component: MyProfile,
    meta: { 
      title: 'Profil Saya | STT Amanat Agung'
    }
  },
  {
    path: '/profil/ganti-password',
    name: CHANGE_PASSWORD,
    component: ChangePassword,
    meta: { 
      title: 'Ganti Password | STT Amanat Agung'
    }
  },
  {
    path: '/recommendation',
    name: RECOMMENDATION,
    component: Recommendation,
    meta: { 
      title: 'Recommendation | STT Amanat Agung'
    }
  },
]
