import {
  DASHBOARD_LOGIN,
  REFERENCE_LIST,
  FORM_LIST,
  DETAIL,
  DASH_DETAIL_REGIST,
  STUDENT_DETAIL_FORM,
  STUDENT_DETAIL_SHEET,
  STUDENT_DETAIL_PAYMENT,
  EVALUATION_SETTING,
  REPORT,
  REGISTRATION_SETTING,
  USER_SETTING,
  ADD_USER,
  LABEL,
  DASHBOARD_FORGOT_PASSWORD,
  LABEL_DETAIL
} from '@/constants/pages'

const Login = () => import(/* webpackChunkName: "dashboard-login" */ '@/apps/dashboard/views/login')
const ForgotPassword = () => import(/* webpackChunkName: "dashboard-forgot-password" */ '@/apps/dashboard/views/forgotPassword')
const ReferenceList = () => import(/* webpackChunkName: "dashboard-reference-list" */ '@/apps/dashboard/views/referenceList')
const FormList = () => import(/* webpackChunkName: "dashboard-form-list" */ '@/apps/dashboard/views/form/formList')
const Detail = () => import(/* webpackChunkName: "dashboard-form-detail" */ '@/apps/dashboard/views/form/detail')
const DashDetailRegistration = () => import(/* webpackChunkName: "dashboard-student-detail-regist" */ '@/apps/dashboard/views/form/detailRegistration')
const StudentDetailForm = () => import(/* webpackChunkName: "dashboard-student-detail-form" */ '@/apps/dashboard/views/form/studentDetailForm')
const StudentDetailSheet = () => import(/* webpackChunkName: "dashboard-student-detail-sheet" */ '@/apps/dashboard/views/form/studentDetailSheet')
const StudentDetailPayment = () => import(/* webpackChunkName: "dashboard-student-detail-sheet" */ '@/apps/dashboard/views/form/studentDetailPayment')
const EvaluationSetting = () => import(/* webpackChunkName: "dashboard-evaluation-setting" */ '@/apps/dashboard/views/form/evaluationSetting')
const Report = () => import(/* webpackChunkName: "dashboard-report" */ '@/apps/dashboard/views/report')
const Label = () => import(/* webpackChunkName: "dashboard-label" */ '@/apps/dashboard/views/label')
const LabelDetail = () => import(/* webpackChunkName: "dashboard-label-detail" */ '@/apps/dashboard/views/labelDetail')
const RegistrationSetting = () => import(/* webpackChunkName: "dashboard-registration-setting" */ '@/apps/dashboard/views/registrationSetting')
const UserSetting = () => import(/* webpackChunkName: "dashboard-user-setting" */ '@/apps/dashboard/views/user/userSetting')
const AddUser = () => import(/* webpackChunkName: "dashboard-add-user" */ '@/apps/dashboard/views/user/addUser')

export default [
  {
    path: '/dashboard',
    redirect: {
      name: DASHBOARD_LOGIN,
    }
  },
  {
    path: '/dashboard/login',
    name: DASHBOARD_LOGIN,
    component: Login,
    meta: { 
      title: 'Masuk | Dashboard STT Amanat Agung'
    }
  },
  {
    path: '/dashboard/forgot-password',
    name: DASHBOARD_FORGOT_PASSWORD,
    component: ForgotPassword,
    meta: { 
      title: 'Lupa Password | Dashboard STT Amanat Agung'
    }
  },
  {
    path: '/dashboard/daftar-mahasiswa',
    name: REFERENCE_LIST,
    component: ReferenceList,
    meta: { 
      title: 'Daftar Mahasiswa | Dashboard STT Amanat Agung'
    }
  },
  {
    path: '/dashboard/daftar-form',
    name: FORM_LIST,
    component: FormList,
    meta: { 
      title: 'Daftar Form | Dashboard STT Amanat Agung'
    }
  },
  {
    path: '/dashboard/daftar-form/detail',
    name: DETAIL,
    component: Detail,
    meta: { 
      title: 'Detail Mahasiswa | Dashboard STT Amanat Agung'
    }
  },
  {
    path: '/dashboard/daftar-form/detail-registration',
    name: DASH_DETAIL_REGIST,
    component: DashDetailRegistration,
    meta: { 
      title: 'Detail Mahasiswa | Dashboard STT Amanat Agung'
    }
  },
  {
    path: '/dashboard/daftar-form/detail-form',
    name: STUDENT_DETAIL_FORM,
    component: StudentDetailForm,
    meta: { 
      title: 'Detail Mahasiswa | Dashboard STT Amanat Agung'
    }
  },
  {
    path: '/dashboard/daftar-form/detail-sheet',
    name: STUDENT_DETAIL_SHEET,
    component: StudentDetailSheet,
    meta: { 
      title: 'Detail Mahasiswa | Dashboard STT Amanat Agung'
    }
  },
  {
    path: '/dashboard/daftar-form/detail-pembayaran',
    name: STUDENT_DETAIL_PAYMENT,
    component: StudentDetailPayment,
    meta: { 
      title: 'Detail Mahasiswa | Dashboard STT Amanat Agung'
    }
  },
  {
    path: '/dashboard/daftar-form/pengaturan',
    name: EVALUATION_SETTING,
    component: EvaluationSetting,
    meta: { 
      title: 'Detail Mahasiswa | Dashboard STT Amanat Agung'
    }
  },
  {
    path: '/dashboard/laporan',
    name: REPORT,
    component: Report,
    meta: { 
      title: 'Laporan | Dashboard STT Amanat Agung'
    }
  },
  {
    path: '/dashboard/pengaturan-pendaftaran',
    name: REGISTRATION_SETTING,
    component: RegistrationSetting,
    meta: { 
      title: 'Pengaturan Pendaftaran | Dashboard STT Amanat Agung'
    }
  },
  {
    path: '/dashboard/pengaturan-pengguna',
    name: USER_SETTING,
    component: UserSetting,
    meta: { 
      title: 'Pengaturan Pengguna | Dashboard STT Amanat Agung'
    },
  },
  {
    path: '/dashboard/pengaturan-pengguna/form',
    name: ADD_USER,
    component: AddUser,
    meta: { 
      title: 'Pengaturan Pengguna | Dashboard STT Amanat Agung'
    },
  },
  {
    path: '/dashboard/label',
    name: LABEL,
    component: Label,
    meta: { 
      title: 'Label | Dashboard STT Amanat Agung'
    },
  },
  {
    path: '/dashboard/label/detail',
    name: LABEL_DETAIL,
    component: LabelDetail,
    meta: { 
      title: 'Label | Dashboard STT Amanat Agung'
    },
  },
]
