import Vue from 'vue'
import VueRouter from 'vue-router'
import { get } from 'dot-prop'
import loadStoreModules from '../utils/apps/loadStoreModules'
import loadscreen from '@/utils/common/loadScreen'
import dialogsession from '@/utils/common/dialogSession'
import { fetchOauth, isTokenValid, getConfig } from '../utils/request'

import dashboard from '@/apps/dashboard/router'
import web from '@/apps/web/router'

import {
  LOGIN,
  DASHBOARD_LOGIN,
  DASHBOARD_FORGOT_PASSWORD,
  NEED_OAUTH,
  PROGRAM_STUDY,
  REFERENCE_LIST,
  RECOMMENDATION,
  FORM_LIST,
  MAHASISWA_PAGES,
  DOSEN_PAGES,
  ADMIN_PAGES,
  STAFF_PAGES
} from '@/constants/pages'

Vue.use(VueRouter)

const routes = [
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  { 
    path: "*",
    name: 'notFound',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/404.vue')
  }
].concat(
  dashboard,
  web
)

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, _, next) => {
  if (to.name === DASHBOARD_LOGIN || to.name === DASHBOARD_FORGOT_PASSWORD) {
    loadscreen.show('white')
  } else {
    loadscreen.show()
  }
  const title = get(to, 'meta.title', 'Web Admisi')
  if (title) {
    document.title = title
  }

  let defaultName = LOGIN
  if (to.path.indexOf('dashboard') !== -1) {
    defaultName = DASHBOARD_LOGIN
  }
  const config = getConfig()
  let defaultPage = REFERENCE_LIST
  if (config && config.role === 'mahasiswa') {
    defaultPage = PROGRAM_STUDY
  } else if (config && config.role === 'dosen') {
    defaultPage = FORM_LIST
  }
  
  loadStoreModules(to.path).then(() => {
    // check token
    const isLogin = get(config, 'isLogin', false)
    if (to.name === RECOMMENDATION) {
      next()
    } else if (!isTokenValid() && NEED_OAUTH.indexOf(to.name) === -1) {
      dialogsession.show()
      setTimeout(() => {
        dialogsession.hide()
        next({
          name: defaultName
        })
      }, 3000)
    } else if (!isTokenValid()) {
      fetchOauth().then(res => {
        if (res.success) {
          const feConfig = {
            token: res.access_token || '',
            expiredAt: res.expires_in || ''
          }
          localStorage.setItem('feConfig', JSON.stringify(feConfig))
        }
        next()
      })
    } else if (isTokenValid() && NEED_OAUTH.indexOf(to.name) !== -1 && isLogin) {
      next({
        name: defaultPage
      })
      loadscreen.hide()
    } else if (isTokenValid() && NEED_OAUTH.indexOf(to.name) === -1 && !isLogin) {
      next({
        name: defaultName
      })
    } else if (isTokenValid() && isLogin) {
      if (
        (config.role === 'mahasiswa' &&  MAHASISWA_PAGES.indexOf(to.name) !== -1)
        || (config.role === 'admin' &&  ADMIN_PAGES.indexOf(to.name) !== -1)
        || (config.role === 'dosen' &&  DOSEN_PAGES.indexOf(to.name) !== -1)
        || (config.role === 'staff_admin' &&  STAFF_PAGES.indexOf(to.name) !== -1)
        ) {
        next()
        return
      }
      next({
        name: defaultPage
      })
    } else {
      next()
    }
    
  }).catch(() => {
    next({
      name: defaultName
    })
  })
})

router.afterEach((to, from) => {
  loadscreen.hide()
})

export default router
