import {
  REGISTRATION_FORM,
  STUDENT_DETAIL_FORM,
  REGISTRATION_SHEET,
  STUDENT_DETAIL_SHEET,
  STUDENT_DETAIL_PAYMENT,
  PAYMENT,
} from '@/constants/pages'

export const STH = [{
  id: '1',
  isComplete: true,
  title: 'Formulir Pendaftaran',
  pathName: [REGISTRATION_FORM, STUDENT_DETAIL_FORM],
  fields: 'is_registration_form'
}, {
  id: '2',
  isComplete: false,
  title: 'Lembar Kesaksian Pertobatan dan Keyakinan Keselamatan',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_repentance_testimony_form'
}, {
  id: '3',
  isComplete: false,
  title: 'Lembar Kesaksian Keyakinan Panggilan Pelayananan',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_faith_calling_form'
}, {
  id: '4',
  isComplete: false,
  title: 'Lembar Pernyataan Persetujuan dari Pasangan (Opsional, lewati jika belum menikah)',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_couple_agreement_form'
}, {
  id: '5',
  isComplete: false,
  title: 'Lembar Keterangan Kesehatan',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_health_form'
}, {
  id: '6',
  isComplete: false,
  title: 'Lembar Pernyataan Dukungan Finansial',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_financial_support_form'
}, {
  id: '7',
  isComplete: false,
  title: 'Formulir Rekomendasi',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_recommendation_form'
}, {
  id: '8',
  isComplete: false,
  title: 'Kelengkapan Data',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_complete_file'
}, {
  id: '9',
  isComplete: false,
  title: 'Pembayaran',
  pathName: [PAYMENT, STUDENT_DETAIL_PAYMENT],
  fields: 'is_receipt_payment'
}]

export const MTH = [{
  id: '1',
  isComplete: true,
  title: 'Formulir Pendaftaran',
  pathName: [REGISTRATION_FORM, STUDENT_DETAIL_FORM],
  fields: 'is_registration_form'
}, {
  id: '2',
  isComplete: false,
  title: 'Lembar Pernyataan Motivasi dan Tujuan Studi di STT Amanat Agung',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_motivation_form'
}, {
  id: '3',
  isComplete: false,
  title: 'Lembar Pernyataan Persetujuan dari Pasangan (Opsional, lewati jika belum menikah)',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_couple_agreement_form'
}, {
  id: '4',
  isComplete: false,
  title: 'Lembar Pernyataan Dukungan Finansial',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_financial_support_form'
}, {
  id: '5',
  isComplete: false,
  title: 'Formulir Rekomendasi',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_recommendation_form'
}, {
  id: '6',
  isComplete: false,
  title: 'Kelengkapan Data',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_complete_file'
}, {
  id: '7',
  isComplete: false,
  title: 'Pembayaran',
  pathName: [PAYMENT, STUDENT_DETAIL_PAYMENT],
  fields: 'is_receipt_payment'
}]

export const MMIN = [{
  id: '1',
  isComplete: true,
  title: 'Formulir Pendaftaran',
  pathName: [REGISTRATION_FORM, STUDENT_DETAIL_FORM],
  fields: 'is_registration_form'
}, {
  id: '2',
  isComplete: false,
  title: 'Lembar Kesaksian Pertobatan dan Keyakinan Keselamatan',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_repentance_testimony_form'
}, {
  id: '3',
  isComplete: false,
  title: 'Lembar Pernyataan Motivasi dan Tujuan Studi di STT Amanat Agung',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_motivation_form'
}, {
  id: '4',
  isComplete: false,
  title: 'Lembar Pernyataan Persetujuan dari Pasangan (Opsional, lewati jika belum menikah)',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_couple_agreement_form'
}, {
  id: '5',
  isComplete: false,
  title: 'Lembar Pernyataan Dukungan Finansial',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_financial_support_form'
}, {
  id: '6',
  isComplete: false,
  title: 'Formulir Rekomendasi',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_recommendation_form'
}, {
  id: '7',
  isComplete: false,
  title: 'Kelengkapan Data',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_complete_file'
}, {
  id: '8',
  isComplete: false,
  title: 'Pembayaran',
  pathName: [PAYMENT, STUDENT_DETAIL_PAYMENT],
  fields: 'is_receipt_payment'
}]

export const MDIV = [{
  id: '1',
  isComplete: true,
  title: 'Formulir Pendaftaran',
  pathName: [REGISTRATION_FORM, STUDENT_DETAIL_FORM],
  fields: 'is_registration_form'
}, {
  id: '2',
  isComplete: false,
  title: 'Lembar Kesaksian Pertobatan dan Keyakinan Keselamatan',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_repentance_testimony_form'
}, {
  id: '3',
  isComplete: false,
  title: 'Lembar Kesaksian Keyakinan Panggilan Pelayananan',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_faith_calling_form'
}, {
  id: '4',
  isComplete: false,
  title: 'Lembar Pernyataan Motivasi dan Tujuan Studi di STT Amanat Agung',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_motivation_form'
}, {
  id: '5',
  isComplete: false,
  title: 'Lembar Pernyataan Persetujuan dari Pasangan (Opsional, lewati jika belum menikah)',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_couple_agreement_form'
}, {
  id: '6',
  isComplete: false,
  title: 'Lembar Keterangan Kesehatan',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_health_form'
}, {
  id: '7',
  isComplete: false,
  title: 'Lembar Pernyataan Dukungan Finansial',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_financial_support_form'
}, {
  id: '8',
  isComplete: false,
  title: 'Formulir Rekomendasi',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_recommendation_form'
}, {
  id: '9',
  isComplete: false,
  title: 'Kelengkapan Data',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_complete_file'
}, {
  id: '10',
  isComplete: false,
  title: 'Pembayaran',
  pathName: [PAYMENT, STUDENT_DETAIL_PAYMENT],
  fields: 'is_receipt_payment'
}]

export const SERT = [{
  id: '1',
  isComplete: true,
  title: 'Formulir Pendaftaran',
  pathName: [REGISTRATION_FORM, STUDENT_DETAIL_FORM],
  fields: 'is_registration_form'
}, {
  id: '2',
  isComplete: false,
  title: 'Lembar Kesaksian Pertobatan dan Keyakinan Keselamatan',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_repentance_testimony_form'
}, {
  id: '3',
  isComplete: false,
  title: 'Lembar Pernyataan Motivasi dan Tujuan Studi di STT Amanat Agung',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_motivation_form'
}, {
  id: '4',
  isComplete: false,
  title: 'Lembar Pernyataan Persetujuan dari Pasangan (Opsional, lewati jika belum menikah)',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_couple_agreement_form'
}, {
  id: '5',
  isComplete: false,
  title: 'Lembar Pernyataan Dukungan Finansial',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_financial_support_form'
}, {
  id: '6',
  isComplete: false,
  title: 'Formulir Rekomendasi',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_recommendation_form'
}, {
  id: '7',
  isComplete: false,
  title: 'Kelengkapan Data',
  pathName: [REGISTRATION_SHEET, STUDENT_DETAIL_SHEET],
  fields: 'is_complete_file'
}, {
  id: '8',
  isComplete: false,
  title: 'Pembayaran',
  pathName: [PAYMENT, STUDENT_DETAIL_PAYMENT],
  fields: 'is_receipt_payment'
}]